.case_study {
    padding-bottom: "70px";
}
.case_study h3 {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 50px;
}
.pagination-area ul {
    display: flex;
    list-style: none;
}

.pagination-disable {
    color: #6c757d !important;
    pointer-events: none;
}

.single-services-box {
    height: 330px;
}

.sectioner-header {
    width: 90%;
}

.sectioner-header {
    width: 69%;
    margin: 0 auto;
}
.sectioner-header p {
    color: #000;
    font-size: 19px;
    font-weight: 600;
}

.section-content {
    margin-top: 80px;
}
.section-content {
    margin-top: 40px;
}
.single-feature {
    margin-bottom: 80px;
    margin-top: 40px;
}
.single-feature h5 {
    font-size: 18px;
}
.media-right-margin {
    margin-right: 25px;
}

.single-feature {
    margin-bottom: 10px;
    margin-top: 20px;
}
.single-feature {
    margin-bottom: 40px;
    margin-top: 20px;
}

.media-right-margin {
    margin-right: 25px;
}
.single-feature p {
    font-size: 15px;
    line-height: 22px;
}
.icon-border span {
    display: block;
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    color: rgb(142, 30, 252);
    font-size: 18px;
    line-height: 50px;
    border: 1px solid rgb(142, 30, 252);
}
.text-right {
    text-align: right !important;
}
.media-right-margin .p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.section-padding {
    padding: 80px 0px;
}
.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}

.text-left {
    text-align: left !important;
}
.feature-mobile {
    margin-top: 50px;
}

.card {
    /* background-color: #f0f0f0; */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

/* .card:hover {
    transform: scale(1.1);
} */

.show-more-less-clickable {
    color: #ff0000; /* Change the color as needed */
    text-decoration: underline;
    cursor: pointer;
}
.main-services-area .section-title {
    text-align: center;
    /* max-width: 800px; */
    margin: -6px auto 60px;
}

.main-services-area {
    background-color: #f2f6f9;
}

.contact-info-new {
    padding: 40px;
}

.contact-info-new .contact-info-content .social {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 20px;
}
.contact-info-new .contact-info-content .social li {
    display: inline-block;
    margin: 0 5px;
}
.contact-info-new .contact-info-content .social li a {
    border: 1px solid #dadada;
    border-radius: 50%;
    color: #aba5a5;
    height: 35px;
    line-height: 35px;
    width: 35px;
}
.contact-info-new .contact-info-content .social li a i {
    font-size: 14px;
}
.contact-info-new .contact-info-content .social li a:hover {
    color: #ffffff;
    border-color: #ff4800;
    background-color: #ff4800;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #0035e5;
}

::-webkit-scrollbar-thumb {
    background: #0035e5;
    border-radius: 5px;
}

:root {
    scroll-behavior: smooth;
}

.card_background {
    background-color: #f2f6f9;
}

.card:hover {
    /* background-color: chocolate; */
    background: linear-gradient(0deg, #ffe4d9 0%, #fff2f4 100%);
    color: #fff;
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    /* border-color: #0076ff; */
}

.why_section .box {
    margin-top: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.why_section .box .img-box {
    margin-bottom: 20px;
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
    border-radius: 100%;
    border: 5px solid #00204a;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.why_section .box .img-box img {
    width: 55px;
    height: auto;
    fill: #00204a;
}
.why_section .box .img-box:hover {
    background: linear-gradient(0deg, #ffc2cc 0%, #fff2f4 100%);
}

.detail-box span {
    color: #000;
    font-size: 16px;
    font-weight: bold;
}

.why_section .box h5 {
    font-weight: bold;
    margin-bottom: 10px;
}

.why_section .box p {
    margin-bottom: 0;
}

.why_section .btn-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 45px;
}

.why_section .btn-box a {
    display: inline-block;
    padding: 10px 45px;
    background-color: #00bbf0;
    color: #ffffff;
    border-radius: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border: none;
}

.why_section .btn-box a:hover {
    background-color: #007fa4;
}

.layout_padding {
    padding: 90px 0;
}

.layout_padding2 {
    padding: 75px 0;
}

.layout_padding2-top {
    padding-top: 75px;
}

.layout_padding2-bottom {
    padding-bottom: 75px;
}

.layout_padding-top {
    padding-top: 90px;
}

.layout_padding-bottom {
    padding-bottom: 90px;
}

.heading_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.heading_container h2 {
    position: relative;
    font-weight: bold;
    margin-bottom: 0;
}

.heading_container h2 span {
    color: #00bbf0;
}

.heading_container p {
    margin-top: 10px;
    margin-bottom: 0;
}

.heading_container.heading_center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.django_sectionOne {
    background: rgba(242, 246, 255, 0.5);
    padding: 60px 0 30px;
}

.flexRow {
    display: flex;
    align-items: center;
}

.django_sectionOne h2 {
    color: #111;
    font-family: "Dosis", sans-serif;
    font-size: 36px;
    font-weight: bold;
}
.lh140 {
    line-height: 140%;
}
.animated3 {
    animation-duration: 1.5s;
    animation-fill-mode: both;
}

.django_sectionOne h3 {
    color: #111;
    /* font-family: Poppins; */
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
}

.lh75 {
    line-height: 175%;
}

.framework-text,
.tech-text {
    /* font-family: proxima-nova, sans-serif; */
    font-size: 17px;
    font-weight: 300;
    line-height: 1.5;
    color: #888;
}

.django_block {
    font-family: "Open Sans";
    margin-top: 34px;
    padding: 32px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 172px;
    border: 1px solid #eee;
    border-radius: 12px;
    background: #fff;
}

.iconsNew {
    width: 70px;
    height: 60px;
    display: inline-flex;
    margin: 0 auto 10px;
}

.remix-icons {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 38px auto;
    width: 40px;
    height: 40px;
}

/* why choose industry */
.section_padding,
.section_padding_bottom {
    padding-bottom: 80px;
}

.salesforce_Head {
    position: sticky;
    top: 180px;
}

.our_approach {
    width: 88%;
}

.our_approach h2 {
    display: flex;
    align-items: center;
    padding: 15px 0;
}
.our_approach h2,
.fixed_border {
    border-bottom: 1px solid #000;
}
.apps_numbersec {
    display: contents;
}

.apps_numbersec img,
.practice-sale-img img {
    min-width: 40px;
    max-width: 1px;
}
.our_approach h2 span {
    font-size: 18px;
    margin-left: 12px;
}

/* section third */
.industries_section .every-heading {
    padding-bottom: 30px;
}
.industries_section .every-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 30px;
}
.industries_section .every-box-icon {
    padding-right: 50px;
}
.industries_section .heading3 {
    color: #333;
    /* font: 26px/1.1 proxima_nova_altsemibold; */
    position: relative;
    /* margin: 0 0 20px; */
}
.industries_section .para {
    color: #333;
    font-size: 14px;
    /* margin: 20px 0; */
    margin-bottom: 20px;
}

.margin_auto {
    margin: 0 auto !important;
}

.main-heading-box h1,
.main-heading-box h2,
.main-heading-box h3 {
    font-size: 40px;
    color: #181b1b;
    font-family: "Dosis", sans-serif;
    margin-bottom: 10px;
}
.industries_section .heading5 {
    margin: 20px 0 25px;
    font-size: 20px;
    font-family: "Dosis", sans-serif;
    /* font: 24px/1.2 proxima_nova_altsemibold; */
    position: relative;
    color: #333;
}

.section-title-industry {
    text-align: center;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    margin-top: -6px;
}

.why-choose-us-image img {
    height: 400px;
    margin-top: 30px;
    border-radius: 10px;
}

.why-choose-us-content {
    margin-bottom: 110px;
}

.section-title-industry h2 {
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
}

.section-title-industry p {
    max-width: 520px;
    margin-bottom: 0;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.why-opt {
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
    text-align: center !important;
}

.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }
    50% {
        transform: translate(0, 15px);
    }
    100% {
        transform: translate(0, -0px);
    }
}

.image-wrapper {
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center;
    background-size: cover; 
    background-repeat: no-repeat;
}
.page-title-area {
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.page-title-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: 0.6;
}

.disabled {
    pointer-events: none;
}

.featured-services-areas {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: transparent;
}

.single-featured-services-boxs {
    position: relative;
    z-index: 1;
    transition: 0.5s;
    padding: 20px 25px;
}
.single-featured-services-boxs .icon {
    margin-bottom: 5px;
    color: #ff4800;
    transition: 0.5s;
}
.single-featured-services-boxs .icon i {
    font-size: 45px;
}
.single-featured-services-boxs .icon i::before {
    font-size: 45px;
}
.single-featured-services-boxs h2 {
    margin-bottom: 0;
    color: #000;
    transition: 0.5s;
    font-size: 23px;
    font-weight: 700;
}
.single-featured-services-boxs h2 a {
    color: #ffffff;
}
.single-featured-services-boxs h2 a:hover {
    text-decoration: underline;
}
.single-featured-services-boxs p {
    color: #000;
    transition: 0.5s;
    margin-top: 12px;
    margin-bottom: 0;
}
.single-featured-services-boxs::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ff4800;
    width: 2px;
    height: 100%;
    transition: 0.5s;
    z-index: -1;
}
.single-featured-services-boxs:hover .icon,
.single-featured-services-boxs.active .icon {
    color: #ffffff;
}
.single-featured-services-boxs:hover h3,
.single-featured-services-boxs.active h3 {
    color: #ffffff;
}
.single-featured-services-boxs:hover h3 a,
.single-featured-services-boxs.active h3 a {
    color: #ffffff;
}
.single-featured-services-boxs:hover p,
.single-featured-services-boxs.active p {
    color: #f0f0f0;
}
.single-featured-services-boxs:hover::before,
.single-featured-services-boxs.active::before {
    width: 100%;
}

.tec_circle {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.industy_img {
    width: "500px";
    height: "600px";
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the overlay is above other content */
}

.footer-span i {
    position: relative;
    left: 0;
    color: #ff4800;
    font-size: 18px;
}

.partner-slides a::before,
.partner-slides a::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
}
.partner-slides a::before {
    left: 0;
    top: 0;
    border-left: 3px solid #ff4800;
    border-top: 3px solid #ff4800;
}
.partner-slides a::after {
    right: 0;
    bottom: 0;
    border-right: 3px solid #ff4800;
    border-bottom: 3px solid #ff4800;
}
.partner-slides a img {
    transition: 0.5s;
}
.partner-slides a:hover::after,
.partner-slides a:hover::before {
    width: 40px;
    height: 40px;
    opacity: 1;
    visibility: visible;
}
.partner-slides a:hover img {
    transform: scale(0.8);
}
.partner-slides:nth-child(6) {
    margin-left: 10%;
}

.cover-image {
    width: 60%;         /* Full width of the container */
    height: 60%;        /* Full height of the container */
    object-fit: cover;   /* Ensures the image covers the container */
    display: block;      /* Removes extra space under the image */
  }