/*
Flaticon icon font: Flaticon
*/
@font-face {
    font-family: "Flaticon";
    src: url("./fonts/Flaticon.eot");
    src: url("./fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
        url("./fonts/Flaticon.woff2") format("woff2"),
        url("./fonts/Flaticon.woff") format("woff"),
        url("./fonts/Flaticon.ttf") format("truetype"),
        url("./fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url("./fonts/Flaticon.svg#Flaticon") format("svg");
    }
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-income:before {
    content: "\f100";
}
.flaticon-automatic:before {
    content: "\f101";
}
.flaticon-locked:before {
    content: "\f102";
}
.flaticon-molecular:before {
    content: "\f103";
}
.flaticon-gear:before {
    content: "\f104";
}
.flaticon-ceo:before {
    content: "\f105";
}
.flaticon-commerce-and-shopping:before {
    content: "\f106";
}
.flaticon-search:before {
    content: "\f107";
}
.flaticon-robot:before {
    content: "\f108";
}
.flaticon-artificial-intelligence:before {
    content: "\f109";
}
.flaticon-machine-learning:before {
    content: "\f10a";
}
.flaticon-play-button:before {
    content: "\f10b";
}
.flaticon-tick:before {
    content: "\f10c";
}
.flaticon-add:before {
    content: "\f10d";
}
.flaticon-remove:before {
    content: "\f10e";
}
.flaticon-facebook-letter-logo:before {
    content: "\f10f";
}
.flaticon-twitter:before {
    content: "\f110";
}
.flaticon-instagram-logo:before {
    content: "\f111";
}
.flaticon-youtube-play-button:before {
    content: "\f112";
}
.flaticon-left-quote:before {
    content: "\f113";
}
.flaticon-right-chevron:before {
    content: "\f114";
}
.flaticon-left-chevron:before {
    content: "\f115";
}
.flaticon-robot-1:before {
    content: "\f116";
}
.flaticon-neural:before {
    content: "\f117";
}
.flaticon-chat:before {
    content: "\f118";
}
