/*================================================
Default CSS
=================================================*/
body {
    padding: 0;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #57647c;
}

a {
    display: inline-block;
    transition: 0.5s;
    text-decoration: none;
    color: #212529;
}
a:hover,
a:focus {
    text-decoration: none;
    color: #ff4800;
}

button {
    outline: 0 !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #212529;
    font-weight: bold;
    font-family: "Dosis", sans-serif;
}

.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}

p {
    font-size: 15px;
    color: #57647c;
    margin-bottom: 15px;
    line-height: 1.8;
}
p:last-child {
    margin-bottom: 0;
}

.ptb-110 {
    padding-top: 110px;
    padding-bottom: 110px;
}

img {
    max-width: 100%;
    height: auto;
}

.bg-f2f6f9 {
    background-color: #f2f6f9;
}

.bg-fafafa {
    background-color: #fafafa;
}

.container-flauid {
    margin-left: auto;
    margin-right: auto;
}

textarea.form-control {
    min-height: 100%;
    height: auto;
}

/* Section title */
.section-title {
    text-align: center;
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    margin-top: -6px;
}
.section-title span {
    display: block;
    color: #0049a0;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 15px;
    font-family: "Dosis", sans-serif;
}
.section-title h2 {
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
}
.section-title p {
    /* max-width: 520px; */
    margin-bottom: 0;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

/* btn-primary */
.btn {
    border: none;
    line-height: initial;
    border-radius: 30px;
    transition: 0.5s;
    padding: 10px 32px 12px;
    position: relative;
    z-index: 1;
    opacity: 1 !important;
    font-size: 16px;
    font-family: "Dosis", sans-serif;
    font-weight: 600;
}

.btn-primary {
    color: #ffffff;
    background-color: #0049a0;
}
.btn-primary.disabled,
.btn-primary:disabled {
    color: #ffffff;
    background-color: #ff4800;
}
.btn-primary:hover,
.btn-primary:focus {
    background-color: #ff4800 !important;
    color: #ffffff !important;
    box-shadow: unset !important;
}

/* learn-more-btn */
.learn-more-btn {
    display: inline-block;
    position: relative;
    color: #ff4800;
    letter-spacing: 0.5px;
    font-size: 15px;
    font-weight: 600;
    font-family: "Dosis", sans-serif;
}
.learn-more-btn i {
    margin-left: 2px;
    position: relative;
    top: -0.6px;
}
.learn-more-btn i::before {
    font-size: 11px;
}
.learn-more-btn::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    background: #ff4800;
    height: 1px;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
}
.learn-more-btn:hover {
    color: #ff4800;
}
.learn-more-btn:hover::before {
    width: 100%;
    opacity: 1;
    visibility: visible;
}

/* Form control */
.form-control {
    height: 45px;
    border: 1px solid #eeeeee;
    background-color: transparent;
    border-radius: 0;
    transition: 0.5s;
    color: #212529;
    line-height: initial;
    padding-left: 13px;
    font-size: 14px;
}
.form-control:hover,
.form-control:focus {
    box-shadow: unset;
}
.form-control::-moz-placeholder {
    color: #999999;
}
.form-control::placeholder {
    color: #999999;
}
.form-control:focus {
    box-shadow: unset;
    border-color: #ff4800;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 9999999;
    transition: 0.9s;
    visibility: visible;
}
.preloader .loader {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: inline-block;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 45%;
    transform: translateY(-45%);
    transition: 0.5s;
}
.preloader .loader .loader-outter {
    position: absolute;
    border: 4px solid #ffffff;
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}
.preloader .loader .loader-inner {
    position: absolute;
    border: 4px solid #ffffff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    border-right: 0;
    border-top-color: transparent;
    animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}
.preloader::before,
.preloader::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 60%;
    z-index: -1;
    background: #ff4800;
    transition: 0.9s;
}
.preloader::after {
    top: auto;
    bottom: 0;
}
.preloader.preloader-deactivate {
    visibility: hidden;
}
.preloader.preloader-deactivate::after,
.preloader.preloader-deactivate::before {
    height: 0;
}
.preloader.preloader-deactivate .loader {
    opacity: 0;
    visibility: hidden;
}

@keyframes loader-outter {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes loader-inner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
/*================================================
Navbar Area CSS
=================================================*/
.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
}
.navbar-brand img.optional-logo {
    display: none;
}

.main-nav {
    background-color: transparent;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;
}
.main-nav .navbar {
    padding-right: 0;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
}
.main-nav .navbar ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}
.main-nav .navbar .navbar-nav {
    font-family: "Dosis", sans-serif;
    margin-left: auto;
}
.main-nav .navbar .navbar-nav .nav-item {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
}
.main-nav .navbar .navbar-nav .nav-item a {
    font-size: 18px;
    font-weight: 600;
    color: #0049a0;
    text-transform: capitalize;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 13px;
    margin-right: 13px;
}
.main-nav .navbar .navbar-nav .nav-item a:hover,
.main-nav .navbar .navbar-nav .nav-item a:focus,
.main-nav .navbar .navbar-nav .nav-item a.active {
    color: #ff4800;
}
.main-nav .navbar .navbar-nav .nav-item a i {
    font-size: 10px;
}
.main-nav .navbar .navbar-nav .nav-item:last-child a {
    margin-right: 0;
}
.main-nav .navbar .navbar-nav .nav-item:first-child a {
    margin-left: 0;
}
.main-nav .navbar .navbar-nav .nav-item:hover a,
.main-nav .navbar .navbar-nav .nav-item:focus a,
.main-nav .navbar .navbar-nav .nav-item.active a {
    color: #ff4800;
}
.main-nav .navbar .navbar-nav .nav-item .dropdown-menu {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    position: absolute;
    border: none;
    top: 80px;
    left: 0;
    width: 230px;
    z-index: 99;
    display: block;
    opacity: 0;
    visibility: hidden;
    border-radius: 0;
    transition: all 0.3s ease-in-out;
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
}
.main-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
    padding: 0;
}
.main-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
    text-transform: capitalize;
    padding: 8px 15px;
    margin: 0;
    color: #212529;
    font-size: 16px;
}
.main-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover,
.main-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus,
.main-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
    color: #ff4800;
}
.main-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    left: 220px;
    top: 0;
    opacity: 0;
    visibility: hidden;
}
.main-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
    color: #212529;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu {
    left: 220px;
    top: 0;
    opacity: 0;
    visibility: hidden;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a {
    color: #212529;
    text-transform: capitalize;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu {
    left: -250px;
    top: 0;
    opacity: 0;
    visibility: hidden;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a {
    color: #212529;
    text-transform: capitalize;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu {
    left: -250px;
    top: 0;
    opacity: 0;
    visibility: hidden;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a {
    color: #212529;
    text-transform: capitalize;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu {
    left: -250px;
    top: 0;
    opacity: 0;
    visibility: hidden;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a {
    color: #212529;
    text-transform: capitalize;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu {
    left: -250px;
    top: 0;
    opacity: 0;
    visibility: hidden;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a {
    color: #212529;
    text-transform: capitalize;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li.active
    a {
    color: #ff4800;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li:hover
    .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li.active
    a {
    color: #ff4800;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li:hover
    .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li.active
    a {
    color: #ff4800;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li:hover
    .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li.active
    a {
    color: #ff4800;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li:hover
    .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li.active
    a {
    color: #ff4800;
}
.main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li:hover
    .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
}
.main-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
    color: #ff4800;
}
.main-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: -15px;
}
.main-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}
.main-nav .navbar .others-options {
    margin-left: 20px;
}
.main-nav .navbar .others-options .cart-btn {
    display: inline-block;
    position: relative;
    top: 3px;
    color: #ffffff;
}
.main-nav .navbar .others-options .cart-btn:hover {
    color: #ff4800;
}
.main-nav .navbar .others-options .cart-btn span {
    position: absolute;
    background-color: #ff4800;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 100%;
    font-size: 9px;
    right: -11px;
    top: -14px;
    color: #ffffff;
}
.main-nav .navbar .others-options .btn {
    margin-left: 20px;
}
.main-nav .navbar .others-options .btn-primary {
    background-color: #ffffff;
    color: #ff4800;
}
.main-nav .navbar .others-options .btn-primary:hover {
    color: #ffffff !important;
    background-color: #ff4800 !important;
}
.main-nav .navbar .others-options .option-item {
    color: #212529;
    display: inline-block;
    position: relative;
    line-height: initial;
    margin-left: 20px;
    top: 4px;
}
.main-nav .navbar .others-options .option-item .search-btn {
    cursor: pointer;
    transition: 0.5s;
    color: #ffffff;
}
.main-nav .navbar .others-options .option-item .search-btn::before {
    font-size: 17px;
}
.main-nav .navbar .others-options .option-item .search-btn:hover {
    color: #ff4800;
}
.main-nav .navbar .others-options .option-item .close-btn {
    cursor: pointer;
    display: none;
    font-size: 17px;
    transition: 0.5s;
    width: 17px;
    color: #ffffff;
}
.main-nav .navbar .others-options .option-item .close-btn.active {
    display: block;
}
.main-nav .navbar .others-options .option-item .close-btn:hover {
    color: #ff4800;
}

.navbar-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 999;
    background-color: transparent;
    transition: 0.5s;
}
.navbar-area.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    transition: 0.5s;
}

.navbar-area.is-sticky .main-nav {
    background-color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
}
.navbar-area.is-sticky .main-nav .navbar .navbar-nav .nav-item a {
    color: #212529;
}
.navbar-area.is-sticky .main-nav .navbar .navbar-nav .nav-item a:hover,
.navbar-area.is-sticky .main-nav .navbar .navbar-nav .nav-item a:focus,
.navbar-area.is-sticky .main-nav .navbar .navbar-nav .nav-item a.active {
    color: #ff4800;
}
.navbar-area.is-sticky .main-nav .navbar .navbar-nav .nav-item:hover a,
.navbar-area.is-sticky .main-nav .navbar .navbar-nav .nav-item:focus a,
.navbar-area.is-sticky .main-nav .navbar .navbar-nav .nav-item.active a {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    a {
    color: #212529;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    a:hover,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    a:focus,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    a {
    color: #212529;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a {
    color: #212529;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a {
    color: #212529;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a {
    color: #212529;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a {
    color: #212529;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a {
    color: #212529;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:hover,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a:focus,
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li.active
    a {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li.active
    a {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li.active
    a {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li
    .dropdown-menu
    li.active
    a {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    .dropdown-menu
    li.active
    a {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li.active
    a {
    color: #ff4800;
}
.navbar-area.is-sticky .main-nav .navbar .others-options .cart-btn {
    color: #212529;
}
.navbar-area.is-sticky .main-nav .navbar .others-options .cart-btn:hover {
    color: #ff4800;
}
.navbar-area.is-sticky .main-nav .navbar .others-options .btn-primary {
    background-color: #0049a0;
    color: #ffffff;
}
.navbar-area.is-sticky .main-nav .navbar .others-options .btn-primary:hover {
    color: #ffffff !important;
    background-color: #ff4800 !important;
}
.navbar-area.is-sticky .main-nav .navbar .others-options .option-item {
    color: #212529;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .others-options
    .option-item
    .search-btn {
    color: #212529;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .others-options
    .option-item
    .search-btn:hover {
    color: #ff4800;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .others-options
    .option-item
    .close-btn {
    color: #212529;
}
.navbar-area.is-sticky
    .main-nav
    .navbar
    .others-options
    .option-item
    .close-btn:hover {
    color: #ff4800;
}
.navbar-area.is-sticky .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
}
.navbar-area.is-sticky .navbar-brand img.main-logo {
    display: none;
}
.navbar-area.is-sticky .navbar-brand img.optional-logo {
    display: block;
}

.navbar-toggler {
    border: none;
    background: #eee !important;
    padding: 10px;
    border-radius: 0;
}
.navbar-toggler:focus {
    box-shadow: none;
}
.navbar-toggler .icon-bar {
    width: 28px;
    transition: all 0.3s;
    background: #ff4800;
    height: 2px;
    display: block;
}
.navbar-toggler .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
    left: 4px;
    position: relative;
}
.navbar-toggler .middle-bar {
    opacity: 0;
    margin: 6px 0;
}
.navbar-toggler .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
    left: 4px;
    position: relative;
}
.navbar-toggler.collapsed .top-bar {
    transform: rotate(0);
    left: 0;
}
.navbar-toggler.collapsed .middle-bar {
    opacity: 1;
}
.navbar-toggler.collapsed .bottom-bar {
    transform: rotate(0);
    left: 0;
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    height: 950px;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.main-banner .container-fluid {
    padding-left: 100px;
    padding-right: 100px;
}

.main-banner-content {
    margin-top: -160px;
    max-width: 720px;
}
.main-banner-content h1 {
    color: #0049a0;
    margin-bottom: 0;
    line-height: 1.1;
    font-size: 70px;
    font-weight: 700;
}
.main-banner-content p {
    font-size: 20px;
    color: #0049a0;
    max-width: 600px;
    margin-top: 25px;
    margin-bottom: 0;
}
.main-banner-content .btn-box {
    margin-top: 30px;
}
.main-banner-content .btn-box .optional-btn {
    display: inline-block;
    color: #ffffff;
    position: relative;
    margin-left: 20px;
    font-family: "Dosis", sans-serif;
    font-size: 16px;
    font-weight: 700;
}
.main-banner-content .btn-box .optional-btn::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transition: 0.5s;
}
.main-banner-content .btn-box .optional-btn::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #ff4800;
    transition: 0.5s;
}
.main-banner-content .btn-box .optional-btn:hover {
    color: #ffffff;
}
.main-banner-content .btn-box .optional-btn:hover::before {
    width: 0;
}
.main-banner-content .btn-box .optional-btn:hover::after {
    width: 100%;
    right: auto;
    left: 0;
}

.banner-section {
    overflow: hidden;
    position: relative;
    z-index: 1;
    /* background-image: url(../../public/images/banner-bg-shape.jpg); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 240px;
    padding-bottom: 120px;
    border-bottom: 1px solid #f9f6f6;
}

.banner-content h1 {
    margin-bottom: 0;
    line-height: 1.1;
    font-size: 67px;
    font-weight: 700;
}
.banner-content p {
    font-size: 15px;
    max-width: 600px;
    margin-top: 25px;
    margin-bottom: 0;
}
.banner-content .btn-box {
    margin-top: 30px;
}
.banner-content .btn-box .optional-btn {
    display: inline-block;
    color: #212529;
    position: relative;
    margin-left: 20px;
    font-family: "Dosis", sans-serif;
    font-size: 16px;
    font-weight: 700;
}
.banner-content .btn-box .optional-btn::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #212529;
    transition: 0.5s;
}
.banner-content .btn-box .optional-btn::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #ff4800;
    transition: 0.5s;
}
.banner-content .btn-box .optional-btn:hover {
    color: #ff4800;
}
.banner-content .btn-box .optional-btn:hover::before {
    width: 0;
}
.banner-content .btn-box .optional-btn:hover::after {
    width: 100%;
    right: auto;
    left: 0;
}

.banner-image {
    position: relative;
    width: 100%;
    height: 100%;
    top: -75px;
}
.banner-image img {
    position: absolute;
}
.banner-image img:nth-child(1) {
    left: 115px;
    bottom: 70px;
    z-index: 4;
    animation-name: rotateMe;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.banner-image img:nth-child(2) {
    left: 87px;
    bottom: 45px;
    z-index: 3;
}
.banner-image img:nth-child(3) {
    right: -43px;
    bottom: -35px;
}
.banner-image img:nth-child(4) {
    left: -40px;
    bottom: -75px;
    z-index: 3;
}
.banner-image img:nth-child(5) {
    left: 40px;
    bottom: -55px;
    z-index: 2;
}
.banner-image img:nth-child(6) {
    left: -45px;
    top: 185px;
    z-index: 1;
}
.banner-image img:nth-child(7) {
    right: 160px;
    top: 0;
}
.banner-image img:nth-child(8) {
    left: 30px;
    bottom: -63px;
}
.banner-image img.main-pic {
    display: none;
}

.hero-banner {
    position: relative;
    z-index: 1;
    /* background-image: url(../../public/images/banner-bg-shape.jpg); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 180px;
    padding-bottom: 100px;
}
.hero-banner .container-fluid {
    padding-left: 70px;
    padding-right: 70px;
}

.hero-banner-content h1 {
    margin-bottom: 0;
    line-height: 1.1;
    font-size: 67px;
    font-weight: 700;
}
.hero-banner-content p {
    font-size: 15px;
    max-width: 600px;
    margin-top: 25px;
    margin-bottom: 0;
}
.hero-banner-content .btn-box {
    margin-top: 30px;
}
.hero-banner-content .btn-box .optional-btn {
    display: inline-block;
    color: #212529;
    position: relative;
    margin-left: 20px;
    font-family: "Dosis", sans-serif;
    font-size: 16px;
    font-weight: 700;
}
.hero-banner-content .btn-box .optional-btn::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #212529;
    transition: 0.5s;
}
.hero-banner-content .btn-box .optional-btn::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #ff4800;
    transition: 0.5s;
}
.hero-banner-content .btn-box .optional-btn:hover {
    color: #ff4800;
}
.hero-banner-content .btn-box .optional-btn:hover::before {
    width: 0;
}
.hero-banner-content .btn-box .optional-btn:hover::after {
    width: 100%;
    right: auto;
    left: 0;
}

.hero-banner-image {
    text-align: center;
}

/*================================================
Featured Services Area CSS
=================================================*/
.featured-services-area {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: transparent;
}

.single-featured-services-box {
    position: relative;
    z-index: 1;
    transition: 0.5s;
    padding: 20px 25px;
}
.single-featured-services-box .icon {
    margin-bottom: 5px;
    color: #ff4800;
    transition: 0.5s;
}
.single-featured-services-box .icon i {
    font-size: 45px;
}
.single-featured-services-box .icon i::before {
    font-size: 45px;
}
.single-featured-services-box h2 {
    margin-bottom: 0;
    color: #ffffff;
    transition: 0.5s;
    font-size: 23px;
    font-weight: 700;
}
.single-featured-services-box h2 a {
    color: #ffffff;
}
.single-featured-services-box h2 a:hover {
    text-decoration: underline;
}
.single-featured-services-box p {
    color: #dddddd;
    transition: 0.5s;
    margin-top: 12px;
    margin-bottom: 0;
}
.single-featured-services-box::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ff4800;
    width: 2px;
    height: 100%;
    transition: 0.5s;
    z-index: -1;
}
.single-featured-services-box:hover .icon,
.single-featured-services-box.active .icon {
    color: #ffffff;
}
.single-featured-services-box:hover h3,
.single-featured-services-box.active h3 {
    color: #ffffff;
}
.single-featured-services-box:hover h3 a,
.single-featured-services-box.active h3 a {
    color: #ffffff;
}
.single-featured-services-box:hover p,
.single-featured-services-box.active p {
    color: #f0f0f0;
}
.single-featured-services-box:hover::before,
.single-featured-services-box.active::before {
    width: 100%;
}

/*================================================
About Area CSS
=================================================*/
.about-area {
    position: relative;
    z-index: 1;
}

.about-image {
    position: relative;
    padding-bottom: 100px;
}
.about-image img:nth-child(2) {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 0 10px 0 0;
}

.about-content {
    padding-left: 25px;
}
.about-content h2 {
    margin-bottom: 18px;
    line-height: 1.3;
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
}
.about-content p {
    margin-bottom: 13px;
}
.about-content .btn {
    margin-top: 15px;
}
.about-content .features-list {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-right: -10px;
    margin-left: -10px;
}
.about-content .features-list li {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 13px;
}
.about-content .features-list li span {
    display: block;
    position: relative;
    color: #212529;
    padding-left: 28px;
    font-family: "Dosis", sans-serif;
    font-weight: 600;
    font-size: 17px;
}
.about-content .features-list li span i {
    width: 20px;
    display: inline-block;
    height: 20px;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 4px;
    color: #ffffff;
    line-height: 16px;
    background-color: #ff4800;
    text-align: center;
    transition: 0.5s;
}
.about-content .features-list li span i::before {
    font-size: 10px;
}
.about-content .features-list li span:hover i {
    background-color: #4ac728;
}

.about-inner-area {
    margin-top: 60px;
}

.about-text h3 {
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 23px;
    font-weight: 700;
}
.about-text p {
    margin-top: 13px;
    margin-bottom: 0;
}

.shape-img1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
    opacity: 0.4;
}

.shape-img2 {
    position: absolute;
    left: 5%;
    top: 5%;
    z-index: -1;
    animation-name: rotateMe;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.shape-img7 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.2;
    animation: rotate3d 4s linear infinite;
}

.shape-img3 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    opacity: 0.1;
    animation: rotate3d 4s linear infinite;
}

.shape-img4 {
    position: absolute;
    right: 40%;
    bottom: 8%;
    z-index: -1;
    animation: animationFramesOne 20s infinite linear;
}

.shape-img5 {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    opacity: 0.7;
    animation-name: rotateMe;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.shape-img6 {
    position: absolute;
    left: 2%;
    bottom: 0;
    z-index: -1;
    opacity: 0.4;
    animation: moveLeftBounce 5s linear infinite;
}

.dot-shape1 {
    position: absolute;
    top: 5%;
    left: 50%;
    z-index: -1;
    animation: animationFramesTwo 40s infinite linear;
    opacity: 0.5;
}

.dot-shape2 {
    position: absolute;
    left: 5%;
    top: 45%;
    z-index: -1;
    animation: animationFramesTwo 30s infinite linear;
    opacity: 0.5;
}

.dot-shape4 {
    position: absolute;
    right: 15%;
    top: 50%;
    z-index: -1;
    animation: rotate3d 4s linear infinite;
}

.dot-shape5 {
    position: absolute;
    left: 30%;
    top: 50%;
    z-index: -1;
    animation: animationFramesOne 20s infinite linear;
}

.dot-shape6 {
    position: absolute;
    right: 45%;
    bottom: 5%;
    z-index: -1;
    animation: rotate3d 4s linear infinite;
}

@keyframes moveBounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(-73px, 1px) rotate(-36deg);
    }
    40% {
        transform: translate(-141px, -72px) rotate(-72deg);
    }
    60% {
        transform: translate(-83px, -122px) rotate(-108deg);
    }
    80% {
        transform: translate(40px, -72px) rotate(-144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes moveLeftBounce {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
/*================================================
Services Area CSS
=================================================*/
.services-area {
    padding-bottom: 90px;
    position: relative;
    z-index: 1;
}
.services-area .pagination-area {
    margin-bottom: 30px;
}

.single-services-box {
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 30px 25px;
    transition: 0.5s;
    position: relative;
}
.single-services-box .icon {
    margin-bottom: 20px;
    background-color: #ffe4d9;
    border-radius: 5px;
    width: 75px;
    height: 75px;
    transition: 0.5s;
    text-align: center;
    color: #ff4800;
}
.single-services-box .icon i {
    font-size: 35px;
    line-height: 75px;
}
.single-services-box h3 {
    margin-bottom: 0;
    font-size: 23px;
    font-weight: 700;
}
.single-services-box p {
    margin-top: 12px;
    margin-bottom: 0;
}
.single-services-box::before {
    content: "";
    height: 3px;
    width: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #ff4800;
    transition: 0.5s;
}
.single-services-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
}
.single-services-box:hover .icon {
    background-color: #ff4800;
    color: #ffffff;
}
.single-services-box:hover::before {
    width: 100%;
    height: 4px;
}

.services-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.services-tab-list .tabs {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.services-tab-list .tabs li {
    transition: background-color 1s;
    margin-bottom: 10px;
    display: block;
    background-color: #ffffff;
    position: relative;
    padding: 20px;
    border-radius: 60px 0px 0px 60px;
    color: #313e5b;
    cursor: pointer;
    font-size: 19px;
    font-weight: 600;
}
.services-tab-list .tabs li i {
    display: inline-block;
    width: 55px;
    height: 55px;
    background: linear-gradient(
        to left bottom,
        #dee4f3,
        #e7eaf6,
        #f0f1f9,
        #f8f8fc,
        #ffffff
    );
    text-align: center;
    line-height: 55px;
    border-radius: 5px;
    margin-right: 12px;
    color: #ff4800;
}
.services-tab-list .tabs li i::before {
    font-size: 30px;
}
.services-tab-list .tabs li:hover {
    background-color: #0049a0;
    color: #ffffff;
}
.services-tab-list .tabs li span {
    position: relative;
    top: -3px;
}
.services-tab-list .tabs li.current {
    background-color: #0049a0;
    color: #ffffff;
}
.services-tab-list .tabs li:last-child {
    margin-bottom: 0;
}
.services-tab-list .tab-content {
    padding-left: 30px;
}
.services-tab-list .tab-content .tabs-item .image {
    margin-bottom: 30px;
}
.services-tab-list .tab-content .tabs-item .content .btn {
    margin-top: 5px;
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-overview {
    align-items: center !important;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 50px;
}
.services-details-overview:first-child {
    margin-top: 0;
}
.services-details-overview .services-details-desc {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px;
    padding-left: 15px;
}
.services-details-overview .services-details-desc h3 {
    margin-bottom: 15px;
    font-size: 28px;
    font-weight: 700;
}
.services-details-overview .services-details-desc ul {
    padding-left: 17px;
    margin: 0;
}
.services-details-overview .services-details-desc ul li {
    line-height: 1.8;
    margin-bottom: 10px;
}
.services-details-overview .services-details-desc ul li:last-child {
    margin-bottom: 0;
}
.services-details-overview
    .services-details-desc
    .services-details-accordion
    .accordion {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 25px;
}
.services-details-overview
    .services-details-desc
    .services-details-accordion
    .accordion
    .accordion-item {
    display: block;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    background: #ffffff;
    margin-bottom: 10px;
}
.services-details-overview
    .services-details-desc
    .services-details-accordion
    .accordion
    .accordion-item:last-child {
    margin-bottom: 0;
}
.services-details-overview
    .services-details-desc
    .services-details-accordion
    .accordion
    .accordion-title {
    padding: 12px 20px 12px 51px;
    color: #212529;
    position: relative;
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;
    font-size: 15px;
    font-weight: 700;
    font-family: "Dosis", sans-serif;
}
.services-details-overview
    .services-details-desc
    .services-details-accordion
    .accordion
    .accordion-title
    i {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    text-align: center;
    height: 100%;
    background: #ff4800;
    color: #ffffff;
}
.services-details-overview
    .services-details-desc
    .services-details-accordion
    .accordion
    .accordion-title
    i::before {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 13px;
}
.services-details-overview
    .services-details-desc
    .services-details-accordion
    .accordion
    .accordion-title.active {
    border-bottom-color: #eeeeee;
}
.services-details-overview
    .services-details-desc
    .services-details-accordion
    .accordion
    .accordion-title.active
    i::before {
    content: "\f068";
}
.services-details-overview
    .services-details-desc
    .services-details-accordion
    .accordion
    .accordion-content {
    display: none;
    position: relative;
    padding: 15px 15px;
}
.services-details-overview
    .services-details-desc
    .services-details-accordion
    .accordion
    .accordion-content.show {
    display: block;
}
.services-details-overview .services-details-image {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px;
    padding-left: 15px;
}
.services-details-overview .services-details-image img {
    border-radius: 10px;
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
    padding-bottom: 80px;
}

.single-team-box {
    margin-bottom: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    transition: 0.5s;
    text-align: center;
}
.single-team-box .image {
    position: relative;
    overflow: hidden;
}
.single-team-box .image img {
    transition: 0.5s;
}
.single-team-box .image .social {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-decoration: none;
    opacity: 0;
    margin-top: -100px;
    padding: 8px 15px;
    background: #ffffff;
    border-radius: 35px;
    visibility: hidden;
    transition: 0.5s;
}
.single-team-box .image .social a {
    display: inline-block;
    color: #ff4800;
    font-size: 17px;
    margin: 0 7px;
}
.single-team-box .image .social a:hover {
    color: #4ac728;
}
.single-team-box .content {
    padding: 25px;
    position: relative;
    transition: 0.5s;
}
.single-team-box .content h3 {
    margin-bottom: 0;
    transition: 0.5s;
    font-size: 23px;
    font-weight: 700;
}
.single-team-box .content span {
    display: block;
    margin-top: 8px;
    color: #ff4800;
    transition: 0.5s;
    font-family: "Dosis", sans-serif;
    font-size: 15px;
    font-weight: 500;
}
.single-team-box:hover {
    transform: translateY(-5px);
}
.single-team-box:hover .image img {
    transform: scale(1.2);
}
.single-team-box:hover .image .social {
    opacity: 1;
    margin-top: 0;
    visibility: visible;
}
.single-team-box:hover .content {
    background-color: #ff4800;
}
.single-team-box:hover .content h3 {
    color: #ffffff;
}
.single-team-box:hover .content span {
    color: #ffffff;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    padding-bottom: 100px;
}

.customers-partner-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.partner-item {
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: 5px;
    padding-right: 5px;
}
.partner-item a {
    display: block;
    padding: 25px;
    background: #ffffff;
    text-align: center;
    position: relative;
    margin-bottom: 10px;
}
.partner-item a::before,
.partner-item a::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
}
.partner-item a::before {
    left: 0;
    top: 0;
    border-left: 3px solid #ff4800;
    border-top: 3px solid #ff4800;
}
.partner-item a::after {
    right: 0;
    bottom: 0;
    border-right: 3px solid #ff4800;
    border-bottom: 3px solid #ff4800;
}
.partner-item a img {
    transition: 0.5s;
}
.partner-item a:hover::after,
.partner-item a:hover::before {
    width: 40px;
    height: 40px;
    opacity: 1;
    visibility: visible;
}
.partner-item a:hover img {
    transform: scale(0.8);
}
.partner-item:nth-child(6) {
    margin-left: 10%;
}

.partner-section {
    position: relative;
    padding-bottom: 80px;
    z-index: 1;
}

.single-partner-item {
    padding: 20px;
    background: #ffffff;
    border-radius: 15px;
    text-align: center;
}
.single-partner-item a {
    display: block;
}
.single-partner-item a img {
    border-radius: 10px;
    width: auto !important;
    display: inline-block !important;
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
    position: relative;
    z-index: 1;
}

.feedback-item {
    position: relative;
    z-index: 3;
    margin-left: auto;
    max-width: 555px;
    padding-top: 100px;
    padding-bottom: 100px;
}
.feedback-item p {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 19px;
    font-style: italic;
}
.feedback-item::before {
    content: "\f113";
    color: #f8b5a6;
    display: inline-block;
    position: relative;
    margin-top: -15px;
    font-style: normal;
    font-size: 50px;
    font-family: Flaticon;
}
.feedback-item .client-info {
    margin-top: 20px;
}
.feedback-item .client-info img {
    width: 75px !important;
    display: none !important;
    margin-bottom: 15px;
    border-radius: 5px;
}
.feedback-item .client-info h3 {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
}
.feedback-item .client-info span {
    display: block;
    font-weight: 300;
    margin-top: 4px;
    color: #ffffff;
}
.feedback-item::after {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background-color: #ff4800;
    z-index: -1;
    display: none;
}

.client-image {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.client-image img {
    display: none !important;
    width: auto !important;
}
.client-image.bg1 {
    background-image: url(../../public/images/clients/client1.webp);
}
.client-image.bg2 {
    background-image: url(../../public/images/clients/client2.webp);
}
.client-image.bg3 {
    background-image: url(../../public/images/clients/client3.webp);
}

.feedback-slides .swiper-slide::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 68%;
    height: 100%;
    z-index: 1;
    background-image: url(../../public/images/right-shape1.webp);
    background-position: right top;
    background-size: cover;
    background-repeat: no-repeat;
}
.feedback-slides .swiper-button-next,
.feedback-slides .swiper-button-prev {
    color: #fff;
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-area {
    position: relative;
    z-index: 1;
    padding-bottom: 80px;
}

.single-pricing-box {
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 30px;
    transition: 0.5s;
    border-radius: 5px;
}
.single-pricing-box .pricing-header h3 {
    margin-bottom: 0;
    font-size: 26px;
    font-weight: 700;
}
.single-pricing-box .pricing-header p {
    transition: 0.5s;
    line-height: 1.6;
    margin-top: 10px;
    margin-bottom: 0;
}
.single-pricing-box .price {
    color: #212529;
    font-size: 50px;
    font-weight: 700;
    font-family: "Dosis", sans-serif;
    margin-bottom: 10px;
}
.single-pricing-box .price span {
    display: inline-block;
    margin-left: -7px;
    font-size: 16px;
    font-weight: 600;
}
.single-pricing-box .buy-btn {
    margin-bottom: 25px;
}
.single-pricing-box .buy-btn .btn-primary::after {
    background: linear-gradient(
        to right top,
        #a3a3a3,
        #9a9a9a,
        #909090,
        #878787,
        #7e7e7e
    );
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    z-index: -1;
    border-radius: 30px;
}
.single-pricing-box .pricing-features {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.single-pricing-box .pricing-features li {
    margin-bottom: 12px;
    color: #57647c;
    position: relative;
    padding-left: 19px;
}
.single-pricing-box .pricing-features li:last-child {
    margin-bottom: 0;
}
.single-pricing-box .pricing-features li i {
    color: #ff4800;
    font-size: 12px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.single-pricing-box .pricing-features li i::before {
    font-size: 12px;
}
.single-pricing-box:hover,
.single-pricing-box.active {
    box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border-color: #ffffff;
}
.single-pricing-box:hover .buy-btn .btn-primary::after,
.single-pricing-box.active .buy-btn .btn-primary::after {
    opacity: 0;
    visibility: hidden;
}

.tab .tabs-item {
    display: none;
}
.tab .tabs-item:first-child {
    display: block;
}

.tabs-item {
    display: none;
}
.tabs-item:first-child {
    display: block;
}

.pricing-tab .tabs {
    padding-left: 0;
    margin-bottom: 45px;
    list-style-type: none;
    text-align: center;
}
.pricing-tab .tabs li {
    display: inline-block;
}
.pricing-tab .tabs li span {
    background-color: #ffffff;
    cursor: pointer;
    display: inline-block;
    padding: 10px 30px 11px;
    margin-left: -2px;
    margin-right: -2px;
    font-family: "Dosis", sans-serif;
    font-weight: 600;
    font-size: 16px;
}
.pricing-tab .tabs li span:hover {
    color: #ffffff;
    background-color: #212529;
}
.pricing-tab .tabs li.current span {
    color: #ffffff;
    background-color: #212529;
}
.pricing-tab .tabs li:nth-child(1) span {
    border-radius: 5px 0 0 5px;
}
.pricing-tab .tabs li:nth-child(1) span::before {
    border-radius: 5px 0 0 5px;
}
.pricing-tab .tabs li:nth-child(2) span {
    border-radius: 0 5px 5px 0;
}
.pricing-tab .tabs li:nth-child(2) span::before {
    border-radius: 0 5px 5px 0;
}

/*================================================
Projects Area CSS
=================================================*/
.projects-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding-bottom: 80px;
}
.projects-area .section-title {
    text-align: left;
    max-width: 540px;
    margin-left: 0;
    margin-right: 0;
}
.projects-area .container-fluid {
    position: relative;
}

.single-projects-box {
    position: relative;
    border-radius: 5px;
    margin-bottom: 30px;
    /* box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175);
    background-color: #ffffff; */
}
/* .single-projects-box img {
    border-radius: 5px;
} */
.single-projects-box img {
    width: 600px;
    border-radius: 5px;
    height: 250px;
}
.single-projects-box .plus-icon a {
    background-color: #ff4800;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 20px;
    right: 20px;
    transform: scale(0);
    transition: all 0.4s ease-out 0s;
    overflow: hidden;
    border-radius: 50%;
}
.single-projects-box .plus-icon a span {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.single-projects-box .plus-icon a span::before,
.single-projects-box .plus-icon a span::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    background-color: #ffffff;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.single-projects-box .plus-icon a span::after {
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.single-projects-box .plus-icon a:hover {
    background-color: #4ac728;
}
.single-projects-box:hover .plus-icon a {
    transform: scale(1);
}

.projects-slides .swiper-button-next,
.projects-slides .swiper-button-prev {
    color: #ff4800;
}

.circle-shape1 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
    position: relative;
    z-index: 1;
    padding-bottom: 80px;
}
.blog-area .pagination-area {
    margin-bottom: 30px;
}

.single-blog-post {
    margin-bottom: 30px;
    transition: 0.5s;
    border-radius: 5px;
    background-color: #f4faff;
    text-align: center;
}
.single-blog-post .entry-thumbnail a img {
    transition: 0.5s;
    border-radius: 5px;
}
.single-blog-post .entry-post-content {
    padding: 30px;
}
.single-blog-post .entry-post-content .entry-meta {
    margin-bottom: 6px;
}
.single-blog-post .entry-post-content .entry-meta ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.single-blog-post .entry-post-content .entry-meta ul li {
    display: inline-block;
    position: relative;
    margin-right: 16px;
    color: #57647c;
    font-family: "Dosis", sans-serif;
    font-size: 15px;
}
.single-blog-post .entry-post-content .entry-meta ul li::before {
    content: "";
    position: absolute;
    right: -7px;
    top: 50%;
    transform: rotate(14deg) translateY(-50%);
    background: #57647c;
    width: 1px;
    height: 13px;
}
.single-blog-post .entry-post-content .entry-meta ul li a {
    display: inline-block;
    color: #57647c;
}
.single-blog-post .entry-post-content .entry-meta ul li a:hover {
    color: #ff4800;
}
.single-blog-post .entry-post-content .entry-meta ul li:last-child {
    margin-right: 0;
}
.single-blog-post .entry-post-content .entry-meta ul li:last-child::before {
    display: none;
}
.single-blog-post .entry-post-content h3 {
    margin-bottom: 0;
    line-height: 1.4;
    text-transform: capitalize;
    font-size: 23px;
    font-weight: 700;
}
.single-blog-post .entry-post-content p {
    margin-top: 10px;
    margin-bottom: 0;
}
.single-blog-post .entry-post-content .learn-more-btn {
    margin-top: 15px;
}
.single-blog-post:hover {
    box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
    transform: translateY(-5px);
    background-color: #ffffff;
}
.single-blog-post:hover .entry-thumbnail a img {
    opacity: 0.7;
}
/*================================================
Free Trial Area CSS
=================================================*/
.free-trial-area {
    position: relative;
    z-index: 1;
}
.free-trial-area::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 68%;
    height: 100%;
    z-index: 1;
    background-image: url(../../public/images/left-shape2.webp);
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat;
}

.free-trial-content {
    position: relative;
    z-index: 2;
    max-width: 555px;
    padding-left: 30px;
    padding-top: 110px;
    padding-bottom: 110px;
}
.free-trial-content h2 {
    margin-bottom: 0;
    color: #ffffff;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
}
.free-trial-content form {
    position: relative;
    margin-top: 25px;
    margin-bottom: 10px;
}
.free-trial-content form .input-newsletter {
    display: block;
    width: 72%;
    height: 45px;
    color: #ffffff;
    border: none;
    outline: 0;
    background-color: #212529;
    border-radius: 30px;
    padding-left: 20px;
}
.free-trial-content form .input-newsletter::-moz-placeholder {
    color: #d5d5d5;
}
.free-trial-content form .input-newsletter::placeholder {
    color: #d5d5d5;
}
.free-trial-content form button {
    position: absolute;
    right: 0;
    top: 0;
    height: 45px;
    border: none;
    padding: 0 30px;
    line-height: 45px;
    background-color: #ffffff;
    border-radius: 30px;
    transition: 0.5s;
    color: #ff4800;
    font-family: "Dosis", sans-serif;
    font-size: 16px;
    font-weight: 600;
}
.free-trial-content form button:hover {
    background-color: #4ac728;
    color: #ffffff;
}
.free-trial-content p {
    color: #ffffff;
    margin-bottom: 0;
    max-width: 350px;
}
.free-trial-content::after {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background-color: #ff4800;
    z-index: -1;
    display: none;
}

.free-trial-image {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../public/images/woman2.webp);
}
.free-trial-image img {
    display: none;
}

.free-trial-section {
    position: relative;
    z-index: 1;
}
.free-trial-section::before {
    width: 40%;
    height: 100%;
    z-index: -1;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(
        to left bottom,
        #f8f9fd,
        #fafafd,
        #fcfcfe,
        #fdfdfe,
        #ffffff
    );
    content: "";
}

.free-trial-img {
    margin-left: -15px;
    text-align: center;
}

.free-trial-text {
    padding-left: 15px;
}
.free-trial-text h2 {
    margin-bottom: 0;
    font-size: 40px;
    font-weight: 700;
}
.free-trial-text form {
    position: relative;
    margin-top: 25px;
    margin-bottom: 10px;
}
.free-trial-text form .input-newsletter {
    display: block;
    width: 72%;
    height: 45px;
    color: #ffffff;
    border: none;
    outline: 0;
    background-color: #212529;
    border-radius: 30px;
    padding-left: 20px;
}
.free-trial-text form .input-newsletter::-moz-placeholder {
    color: #d5d5d5;
    -moz-transition: 0.5s;
    transition: 0.5s;
}
.free-trial-text form .input-newsletter::placeholder {
    color: #d5d5d5;
    transition: 0.5s;
}
.free-trial-text form .input-newsletter:focus::-moz-placeholder {
    color: transparent;
}
.free-trial-text form .input-newsletter:focus::placeholder {
    color: transparent;
}
.free-trial-text form button {
    position: absolute;
    right: 0;
    top: 0;
    height: 45px;
    border: none;
    padding: 0 30px;
    line-height: 45px;
    background-color: #ff4800;
    border-radius: 30px;
    transition: 0.5s;
    color: #ffffff;
    font-family: "Dosis", sans-serif;
    font-size: 16px;
    font-weight: 600;
}
.free-trial-text form button:hover {
    background-color: #4ac728;
    color: #ffffff;
}
.free-trial-text p {
    margin-bottom: 0;
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details .article-content {
    margin-top: 30px;
}
.blog-details .article-content .entry-meta {
    margin-bottom: 10px;
}
.blog-details .article-content .entry-meta ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    font-family: "Dosis", sans-serif;
}
.blog-details .article-content .entry-meta ul li {
    position: relative;
    display: inline-block;
    color: #212529;
    margin-right: 25px;
    font-size: 15px;
}
.blog-details .article-content .entry-meta ul li span {
    display: inline-block;
    color: #212529;
    font-weight: 600;
    padding-right: 5px;
}
.blog-details .article-content .entry-meta ul li a {
    display: inline-block;
    color: #57647c;
}
.blog-details .article-content .entry-meta ul li a:hover {
    color: #ff4800;
}
.blog-details .article-content .entry-meta ul li i {
    color: #ff4800;
    margin-right: 2px;
}
.blog-details .article-content .entry-meta ul li::before {
    content: "";
    position: absolute;
    top: 11px;
    right: -15px;
    width: 6px;
    height: 1px;
    background: #ff4800;
}
.blog-details .article-content .entry-meta ul li:last-child {
    margin-right: 0;
}
.blog-details .article-content .entry-meta ul li:last-child::before {
    display: none;
}
.blog-details .article-content h1 {
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 700;
}
.blog-details .article-content h2 {
    margin-bottom: 15px;
    font-size: 27px;
    font-weight: 700;
}
.blog-details .article-content h3 {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 700;
}
.blog-details .article-content h4 {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 700;
}
.blog-details .article-content h5 {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 700;
}
.blog-details .article-content .wp-block-gallery.columns-3 {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 25px;
    margin-top: 25px;
}
.blog-details .article-content .wp-block-gallery.columns-3 li {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    padding-right: 10px;
    padding-left: 10px;
}
.blog-details .article-content .wp-block-gallery.columns-3 li figure {
    margin-bottom: 0;
}
.blog-details .article-footer {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    margin-top: 30px;
}
.blog-details .article-footer .article-tags {
    flex: 0 0 50%;
    max-width: 50%;
}
.blog-details .article-footer .article-tags span {
    display: inline-block;
    color: #212529;
    font-size: 20px;
    margin-right: 10px;
    position: relative;
    top: 2px;
}
.blog-details .article-footer .article-tags a {
    display: inline-block;
    color: #818181;
    margin-right: 10px;
    position: relative;
}
.blog-details .article-footer .article-tags a::before {
    content: ",";
    position: absolute;
    right: -4px;
}
.blog-details .article-footer .article-tags a::before:last-child {
    display: none;
}
.blog-details .article-footer .article-tags a:last-child {
    margin-right: 0;
}
.blog-details .article-footer .article-tags a:hover {
    color: #ff4800;
}
.blog-details .article-footer .article-share {
    flex: 0 0 50%;
    max-width: 50%;
}
.blog-details .article-footer .article-share .social {
    padding-left: 0;
    list-style-type: none;
    text-align: right;
    margin-bottom: 0;
}
.blog-details .article-footer .article-share .social li {
    display: inline-block;
    margin-left: 6px;
}
.blog-details .article-footer .article-share .social li a {
    color: #212529;
    background-color: #f7f7f7;
    width: 33px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    border-radius: 50%;
    font-size: 13px;
}
.blog-details .article-footer .article-share .social li a:hover {
    color: #ffffff;
    background-color: #ff4800;
}

blockquote,
.blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    font-family: "Dosis", sans-serif;
    margin-bottom: 20px;
    margin-top: 20px;
}
blockquote p,
.blockquote p {
    color: #212529;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
}
blockquote cite,
.blockquote cite {
    display: none;
}
blockquote::before,
.blockquote::before {
    color: #efefef;
    content: "\f10d";
    position: absolute;
    left: 50px;
    top: -60px;
    z-index: -1;
    font-family: "Font Awesome 5 Free";
    font-size: 140px;
    font-weight: 900;
}

.comments-area {
    margin-top: 30px;
}
.comments-area .comments-title {
    line-height: initial;
    margin-bottom: 30px;
    font-size: 23px;
    font-weight: 700;
}
.comments-area ol,
.comments-area ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.comments-area .comment-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.comments-area .children {
    margin-left: 20px;
}
.comments-area .comment-body {
    border-bottom: 1px solid #eeeeee;
    padding-left: 65px;
    color: #212529;
    font-size: 14px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.comments-area .comment-body .reply {
    margin-top: 15px;
}
.comments-area .comment-body .reply a {
    border: 1px solid #eeeeee;
    color: #57647c;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 30px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    font-family: "Dosis", sans-serif;
}
.comments-area .comment-body .reply a:hover {
    color: #ffffff;
    background-color: #ff4800;
    border-color: #ff4800;
}
.comments-area .comment-meta {
    margin-bottom: 0.8em;
}
.comments-area .comment-author {
    font-size: 16px;
    margin-bottom: 0.4em;
    position: relative;
    z-index: 2;
}
.comments-area .comment-author .avatar {
    height: 50px;
    left: -65px;
    position: absolute;
    width: 50px;
}
.comments-area .comment-author .fn {
    font-weight: 700;
    font-family: "Dosis", sans-serif;
}
.comments-area .comment-author .says {
    display: none;
}
.comments-area .comment-metadata {
    color: #57647c;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
}
.comments-area .comment-metadata a {
    color: #57647c;
}
.comments-area .comment-metadata a:hover {
    color: #ff4800;
}
.comments-area .comment-content p {
    font-size: 14px;
}
.comments-area .comment-respond {
    background-color: #f1f1f1;
    padding: 30px;
}
.comments-area .comment-respond .comment-reply-title {
    margin-bottom: 0;
    font-size: 23px;
    font-weight: 700;
}
.comments-area
    .comment-respond
    .comment-reply-title
    #cancel-comment-reply-link {
    font-size: 15px;
    display: inline-block;
}
.comments-area .comment-respond .comment-form {
    overflow: hidden;
}
.comments-area .comment-respond .comment-notes {
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 10px;
}
.comments-area .comment-respond .comment-notes .required {
    color: red;
}
.comments-area .comment-respond .comment-form-comment {
    margin-top: 15px;
    float: left;
    width: 100%;
}
.comments-area .comment-respond label {
    display: block;
    margin-bottom: 5px;
    color: #212529;
    font-weight: 600;
    font-family: "Dosis", sans-serif;
}
.comments-area .comment-respond input[type="date"],
.comments-area .comment-respond input[type="time"],
.comments-area .comment-respond input[type="datetime-local"],
.comments-area .comment-respond input[type="week"],
.comments-area .comment-respond input[type="month"],
.comments-area .comment-respond input[type="text"],
.comments-area .comment-respond input[type="email"],
.comments-area .comment-respond input[type="url"],
.comments-area .comment-respond input[type="password"],
.comments-area .comment-respond input[type="search"],
.comments-area .comment-respond input[type="tel"],
.comments-area .comment-respond input[type="number"],
.comments-area .comment-respond textarea {
    display: block;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    padding: 0.625em 0.7375em;
    outline: 0;
    transition: 0.5s;
}
.comments-area .comment-respond input[type="date"]:focus,
.comments-area .comment-respond input[type="time"]:focus,
.comments-area .comment-respond input[type="datetime-local"]:focus,
.comments-area .comment-respond input[type="week"]:focus,
.comments-area .comment-respond input[type="month"]:focus,
.comments-area .comment-respond input[type="text"]:focus,
.comments-area .comment-respond input[type="email"]:focus,
.comments-area .comment-respond input[type="url"]:focus,
.comments-area .comment-respond input[type="password"]:focus,
.comments-area .comment-respond input[type="search"]:focus,
.comments-area .comment-respond input[type="tel"]:focus,
.comments-area .comment-respond input[type="number"]:focus,
.comments-area .comment-respond textarea:focus {
    border-color: #ff4800;
}
.comments-area .comment-respond .comment-form-author {
    float: left;
    width: 50%;
    padding-right: 10px;
    margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-email {
    float: left;
    width: 50%;
    padding-left: 12px;
    margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-url {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent {
    width: 100%;
    float: left;
    position: relative;
    padding-left: 20px;
    margin-bottom: 20px;
}
.comments-area .comment-respond .comment-form-cookies-consent input {
    position: absolute;
    left: 0;
    top: 6px;
}
.comments-area .comment-respond .comment-form-cookies-consent label {
    display: inline-block;
    margin: 0;
    color: #57647c;
    font-weight: normal;
}
.comments-area .comment-respond .form-submit {
    float: left;
    width: 100%;
}
.comments-area .comment-respond .form-submit input {
    background-color: #ff4800;
    border: none;
    color: #ffffff;
    padding: 10.5px 25px;
    display: inline-block;
    cursor: pointer;
    outline: 0;
    border-radius: 0;
    text-transform: uppercase;
    transition: 0.5s;
    font-family: "Dosis", sans-serif;
    font-weight: 700;
    font-size: 14px;
}
.comments-area .comment-respond .form-submit input:hover,
.comments-area .comment-respond .form-submit input:focus {
    color: #ffffff;
    background-color: #4ac728;
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    padding-top: 210px;
    padding-bottom: 140px;
    background-color: #000000;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.page-title-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #000000;
    opacity: 0.6;
}
/* .page-title-area.item-bg1 {
    background-image: url(../../public/images/page-title-bg1.webp);
}
.page-title-area.item-bg2 {
    background-image: url(../../public/images/page-title-bg2.webp);
}
.page-title-area.item-bg3 {
    background-image: url(../../public/images/page-title-bg3.webp);
} */

.page-title-content h2 {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 40px;
    font-weight: 700;
}
.page-title-content ul {
    padding-left: 0;
    list-style-type: none;
    font-family: "Dosis", sans-serif;
    margin-top: 15px;
    margin-bottom: 0;
}
.page-title-content ul li {
    display: inline-block;
    color: #ffffff;
    margin-right: 14px;
    margin-left: 10px;
    position: relative;
    font-weight: 600;
    font-size: 16px;
}
.page-title-content ul li a {
    color: #ff4800;
}
.page-title-content ul li a:hover {
    color: #ff4800;
}
.page-title-content ul li::before {
    content: "\f054";
    position: absolute;
    right: -15px;
    top: 6px;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    font-size: 10px;
}
.page-title-content ul li:last-child {
    margin-right: 0;
}
.page-title-content ul li:last-child::before {
    display: none;
}
.page-title-content ul li:first-child {
    margin-left: 0;
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
    margin-top: 40px;
    text-align: center;
    font-family: "Dosis", sans-serif;
}
.pagination-area .page-numbers {
    width: 40px;
    height: 40px;
    margin: 0 3px;
    display: inline-block;
    background-color: #ffffff;
    line-height: 40px;
    color: #212529;
    box-shadow: 0 2px 10px 0 #d8dde6;
    font-size: 16px;
    font-weight: 700;
}
.pagination-area .page-numbers.current,
.pagination-area .page-numbers:hover,
.pagination-area .page-numbers:focus {
    background: #ff4800;
    color: #ffffff;
    box-shadow: unset;
}

/*================================================
Sidebar Area CSS
=================================================*/
.widget-area {
    padding-left: 15px;
}
.widget-area .widget {
    margin-top: 30px;
}
.widget-area .widget:first-child {
    margin-top: 0;
}
.widget-area .widget .widget-title {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-transform: capitalize;
    position: relative;
    font-weight: 700;
    font-size: 20px;
}
.widget-area .widget .widget-title::before {
    content: "";
    position: absolute;
    background: #ff4800;
    bottom: -1px;
    left: 0;
    width: 50px;
    height: 1px;
}
.widget-area .widget_search {
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
    background-color: #ffffff;
    padding: 15px;
}
.widget-area .widget_search form {
    position: relative;
}
.widget-area .widget_search form label {
    display: block;
    margin-bottom: 0;
}
.widget-area .widget_search form .screen-reader-text {
    display: none;
}
.widget-area .widget_search form .search-field {
    background-color: transparent;
    height: 50px;
    padding: 6px 15px;
    border: 1px solid #eeeeee;
    width: 100%;
    display: block;
    outline: 0;
    transition: 0.5s;
}
.widget-area .widget_search form .search-field:focus {
    border-color: #ff4800;
}
.widget-area .widget_search form button {
    position: absolute;
    right: 0;
    outline: 0;
    bottom: 0;
    height: 50px;
    width: 50px;
    z-index: 1;
    border: none;
    color: #ffffff;
    background-color: #ff4800;
    transition: 0.5s;
}
.widget-area .widget_search form button:hover {
    background-color: #4ac728;
    color: #ffffff;
}
.widget-area .widget_posts_thumb {
    position: relative;
    overflow: hidden;
}
.widget-area .widget_posts_thumb .item {
    overflow: hidden;
    margin-bottom: 15px;
}
.widget-area .widget_posts_thumb .item:last-child {
    margin-bottom: 0;
}
.widget-area .widget_posts_thumb .item .thumb {
    float: left;
    height: 80px;
    overflow: hidden;
    position: relative;
    width: 80px;
    margin-right: 15px;
}
.widget-area .widget_posts_thumb .item .thumb .fullimage {
    width: 80px;
    height: 80px;
    display: inline-block;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center center !important;
}
.widget-area .widget_posts_thumb .item .thumb .fullimage.bg1 {
    background-image: url(../../public/images/blog/blog1.webp);
}
.widget-area .widget_posts_thumb .item .thumb .fullimage.bg2 {
    background-image: url(../../public/images/blog/blog2.webp);
}
.widget-area .widget_posts_thumb .item .thumb .fullimage.bg3 {
    background-image: url(../../public/images/blog/blog3.webp);
}
.widget-area .widget_posts_thumb .item .info {
    overflow: hidden;
}
.widget-area .widget_posts_thumb .item .info time {
    display: block;
    color: #57647c;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 3px;
    font-size: 11px;
}
.widget-area .widget_posts_thumb .item .info .title {
    margin-bottom: 0;
    line-height: 1.4;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
}
.widget-area .widget_recent_entries ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.widget-area .widget_recent_entries ul li {
    position: relative;
    margin-bottom: 12px;
    color: #57647c;
    padding-left: 14px;
    line-height: 1.5;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 16px;
    font-family: "Dosis", sans-serif;
}
.widget-area .widget_recent_entries ul li:last-child {
    margin-bottom: 0;
}
.widget-area .widget_recent_entries ul li::before {
    background: #ff4800;
    position: absolute;
    height: 7px;
    width: 7px;
    content: "";
    left: 0;
    top: 8px;
}
.widget-area .widget_recent_entries ul li a {
    color: #212529;
}
.widget-area .widget_recent_entries ul li a:hover {
    color: #ff4800;
}
.widget-area .widget_recent_entries ul li .post-date {
    display: block;
    font-size: 12px;
    color: #57647c;
    margin-top: 6px;
    font-weight: normal;
    text-transform: uppercase;
}
.widget-area .widget_recent_comments ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.widget-area .widget_recent_comments ul li {
    position: relative;
    margin-bottom: 12px;
    color: #212529;
    padding-left: 14px;
    line-height: 1.5;
    font-size: 14.5px;
    font-weight: 600;
    font-family: "Dosis", sans-serif;
}
.widget-area .widget_recent_comments ul li:last-child {
    margin-bottom: 0;
}
.widget-area .widget_recent_comments ul li::before {
    background: #ff4800;
    height: 7px;
    width: 7px;
    content: "";
    left: 0;
    top: 7px;
    position: absolute;
}
.widget-area .widget_recent_comments ul li span {
    display: inline-block;
}
.widget-area .widget_recent_comments ul li a {
    color: #57647c;
    display: inline-block;
}
.widget-area .widget_recent_comments ul li a:hover {
    color: #ff4800;
}
.widget-area .widget_archive ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.widget-area .widget_archive ul li {
    position: relative;
    margin-bottom: 12px;
    padding-left: 14px;
    color: #57647c;
    font-size: 14.5px;
    font-weight: 600;
    font-family: "Dosis", sans-serif;
}
.widget-area .widget_archive ul li:last-child {
    margin-bottom: 0;
}
.widget-area .widget_archive ul li::before {
    background: #ff4800;
    height: 7px;
    width: 7px;
    content: "";
    left: 0;
    top: 7px;
    position: absolute;
}
.widget-area .widget_archive ul li a {
    color: #57647c;
}
.widget-area .widget_archive ul li a:hover {
    color: #ff4800;
}
.widget-area .widget_categories ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.widget-area .widget_categories ul li {
    position: relative;
    margin-bottom: 12px;
    color: #57647c;
    padding-left: 14px;
    font-size: 14.5px;
    font-family: "Dosis", sans-serif;
    font-weight: 600;
}
.widget-area .widget_categories ul li:last-child {
    margin-bottom: 0;
}
.widget-area .widget_categories ul li::before {
    background: #ff4800;
    height: 7px;
    width: 7px;
    content: "";
    left: 0;
    top: 7px;
    position: absolute;
}
.widget-area .widget_categories ul li a {
    color: #57647c;
}
.widget-area .widget_categories ul li a:hover {
    color: #ff4800;
}
.widget-area .widget_categories ul li .post-count {
    float: right;
}
.widget-area .widget_meta ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.widget-area .widget_meta ul li {
    position: relative;
    margin-bottom: 12px;
    color: #57647c;
    padding-left: 14px;
    font-size: 14.5px;
    font-family: "Dosis", sans-serif;
    font-weight: 600;
}
.widget-area .widget_meta ul li:last-child {
    margin-bottom: 0;
}
.widget-area .widget_meta ul li::before {
    background: #ff4800;
    height: 7px;
    width: 7px;
    content: "";
    left: 0;
    top: 7px;
    position: absolute;
}
.widget-area .widget_meta ul li a {
    color: #57647c;
}
.widget-area .widget_meta ul li a:hover {
    color: #ff4800;
}
.widget-area .widget_tag_cloud .widget-title {
    margin-bottom: 12px;
}
.widget-area .tagcloud a {
    display: inline-block;
    color: #57647c;
    font-weight: 600;
    font-size: 12.5px !important;
    padding: 6px 13px;
    border: 1px dashed #eeeeee;
    margin-top: 10px;
    margin-right: 10px;
}
.widget-area .tagcloud a:hover,
.widget-area .tagcloud a:focus {
    color: #ffffff;
    background-color: #ff4800;
    border-color: #ff4800;
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    height: 100vh;
}

.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;
}
.error-content h3 {
    font-size: 40px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 17px;
}
.error-content p {
    max-width: 520px;
    margin: 0 auto 20px;
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-content .section-title {
    text-align: left;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
}
.faq-content .section-title p {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.faq-accordion .accordion {
    border: none;
}
.faq-accordion .accordion .accordion__item {
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    background: #ffffff;
    margin-bottom: 10px;
    position: relative;
    border: none;
}
.faq-accordion .accordion .accordion__item:last-child {
    margin-bottom: 0;
}
.faq-accordion .accordion .accordion__button {
    font-family: "Dosis", sans-serif;
    border-bottom: none;
    color: #333333;
    cursor: pointer;
    padding: 12px 20px 12px 51px;
    margin: 0;
    text-decoration: none;
    transition: 0.5s;
    position: relative;
    font-size: 15px;
    font-weight: 700;
    background: #ffffff;
}
.faq-accordion .accordion .accordion__button:focus {
    outline: 0;
}
.faq-accordion .accordion .accordion__button span::before {
    background-color: #ff4800;
    position: absolute;
    height: 100%;
    width: 40px;
    content: "";
    left: 0;
    top: 0;
    z-index: 0;
}
.faq-accordion .accordion .accordion__button:before {
    margin-right: 0;
    border-bottom: 3px solid #fff;
    border-right: none;
    transform: rotate(0deg);
    height: 13px;
    width: 13px;
    position: absolute;
    left: 15px;
    top: 24%;
    z-index: 1;
}
.faq-accordion .accordion .accordion__button::after {
    content: "";
    margin-right: 0;
    border-right: 3px solid #fff;
    transform: rotate(0deg);
    margin-top: 5px;
    height: 13px;
    width: 13px;
    position: absolute;
    left: 10px;
    top: 24%;
    z-index: 1;
}
.faq-accordion .accordion .accordion__button[aria-expanded="true"]::after,
.faq-accordion .accordion .accordion__button[aria-selected="true"]::after {
    display: none;
}
.faq-accordion .accordion .accordion__button[aria-expanded="true"]::before,
.faq-accordion .accordion .accordion__button[aria-selected="true"]::before {
    transform: rotate(0deg);
}
.faq-accordion .accordion .accordion__panel {
    padding: 15px;
    border-top: 1px solid #eeeeee;
}
.faq-accordion .accordion .accordion__panel p:last-child {
    margin-bottom: 0;
}

.faq-contact {
    margin-top: 110px;
}

.faq-contact-form {
    text-align: center;
}
.faq-contact-form form {
    padding-left: 50px;
}
.faq-contact-form form .form-group {
    margin-bottom: 20px;
}
.faq-contact-form form .form-control {
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
    height: 50px;
    font-family: "Dosis", sans-serif;
    font-size: 16px;
}
.faq-contact-form form .row {
    margin-left: -10px;
    margin-right: -10px;
}
.faq-contact-form form .row .col-lg-12,
.faq-contact-form form .row .col-lg-6 {
    padding-left: 10px;
    padding-right: 10px;
}
.faq-contact-form form textarea.form-control {
    height: auto;
    padding-top: 15px;
}
.faq-contact-form form .btn {
    padding: 13px 35px 15px;
    font-size: 17px;
    margin-top: 8px;
    box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}
.faq-contact-form form .btn.disabled {
    cursor: not-allowed !important;
}
.faq-contact-form form .btn.disabled:hover::before,
.faq-contact-form form .btn.disabled:focus::before {
    opacity: 1;
    visibility: visible;
}
.faq-contact-form form .btn.disabled:hover::after,
.faq-contact-form form .btn.disabled:focus::after {
    opacity: 0;
    visibility: hidden;
}
.faq-contact-form form .help-block ul {
    padding-left: 0;
    list-style-type: none;
    margin-top: 5px;
    margin-bottom: 0;
}
.faq-contact-form form .help-block ul li {
    color: red;
}
.faq-contact-form form #msgSubmit {
    margin-bottom: 0;
}
.faq-contact-form form #msgSubmit.text-danger,
.faq-contact-form form #msgSubmit.text-success {
    margin-top: 8px;
    font-size: 23px;
    font-weight: 700;
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
    position: relative;
    z-index: 1;
}
.coming-soon-area .coming-soon-content {
    height: 100vh;
    position: relative;
    padding-left: 100px;
    padding-right: 100px;
}
.coming-soon-area .coming-soon-content .logo {
    position: absolute;
    left: 100px;
    top: 30px;
}
.coming-soon-area .coming-soon-content h3 {
    text-transform: capitalize;
    line-height: 1.3;
    margin-bottom: 25px;
    font-size: 40px;
    font-weight: 700;
}
.coming-soon-area .coming-soon-content form {
    max-width: 520px;
}
.coming-soon-area .coming-soon-content form .form-group {
    margin-bottom: 25px;
}
.coming-soon-area .coming-soon-content form .form-control {
    height: 50px;
    border: none;
    box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}
.coming-soon-area .coming-soon-content form .btn {
    display: block;
    width: 100%;
    padding: 15px 28px;
    box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
}
.coming-soon-area .coming-soon-content form p {
    margin-bottom: 0;
    margin-top: 15px;
}
.coming-soon-area .coming-soon-content .social {
    position: absolute;
    left: 100px;
    bottom: 30px;
    text-align: center;
}
.coming-soon-area .coming-soon-content .social ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.coming-soon-area .coming-soon-content .social ul li {
    display: inline-block;
    margin-right: 10px;
}
.coming-soon-area .coming-soon-content .social ul li:last-child {
    margin-right: 0;
}
.coming-soon-area .coming-soon-content .social ul li a {
    width: 35px;
    height: 35px;
    line-height: 37px;
    border-radius: 50%;
    background-color: #dddddd;
    color: #212529;
    font-size: 14px;
}
.coming-soon-area .coming-soon-content .social ul li a.twitter {
    background-color: #1da1f2;
    color: #ffffff;
}
.coming-soon-area .coming-soon-content .social ul li a.youtube {
    background-color: #ff0000;
    color: #ffffff;
}
.coming-soon-area .coming-soon-content .social ul li a.facebook {
    background-color: #3b5998;
    color: #ffffff;
}
.coming-soon-area .coming-soon-content .social ul li a.linkedin {
    background-color: #0077b5;
    color: #ffffff;
}
.coming-soon-area .coming-soon-content .social ul li a.instagram {
    background-color: #c13584;
    color: #ffffff;
}
.coming-soon-area .coming-soon-content .social ul li a:hover {
    background-color: #ff4800;
    color: #ffffff;
}
.coming-soon-area .coming-soon-time {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    z-index: 1;
    background-image: url(../../public/images/coming-soon-bg.webp);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.coming-soon-area .coming-soon-time img {
    display: none;
}
.coming-soon-area .coming-soon-time #timer {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
}
.coming-soon-area .coming-soon-time #timer div {
    width: 155px;
    height: 155px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    display: inline-block;
    color: #ffffff;
    line-height: initial;
    padding-top: 37px;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 45px;
    font-weight: 700;
    font-family: "Dosis", sans-serif;
}
.coming-soon-area .coming-soon-time #timer div span {
    text-transform: uppercase;
    display: block;
    margin-top: 3px;
    font-size: 14px;
    font-weight: 600;
}
.coming-soon-area .coming-soon-time::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #000000;
    opacity: 0.7;
    z-index: -1;
}

/*================================================
Contact Area CSS
=================================================*/
.contact-area .section-title {
    margin-bottom: 40px;
}

.contact-form {
    text-align: center;
}
.contact-form form {
    padding-left: 50px;
}
.contact-form form .form-group {
    margin-bottom: 20px;
}
.contact-form form .form-control {
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
    height: 50px;
    font-family: "Dosis", sans-serif;
    font-size: 16px;
}
.contact-form form .row {
    margin-left: -10px;
    margin-right: -10px;
}
.contact-form form .row .col-lg-12,
.contact-form form .row .col-lg-6 {
    padding-left: 10px;
    padding-right: 10px;
}
.contact-form form textarea.form-control {
    height: auto;
    padding-top: 15px;
}
.contact-form form .btn {
    padding: 13px 35px 15px;
    font-size: 17px;
    margin-top: 10px;
    box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.5);
}
.contact-form form .btn.disabled {
    cursor: not-allowed !important;
}
.contact-form form .btn.disabled:hover::before,
.contact-form form .btn.disabled:focus::before {
    opacity: 1;
    visibility: visible;
}
.contact-form form .btn.disabled:hover::after,
.contact-form form .btn.disabled:focus::after {
    opacity: 0;
    visibility: hidden;
}
.contact-form form .help-block ul {
    padding-left: 0;
    list-style-type: none;
    margin-top: 5px;
    margin-bottom: 0;
}
.contact-form form .help-block ul li {
    color: red;
}
.contact-form form #msgSubmit {
    margin-bottom: 0;
}
.contact-form form #msgSubmit.text-danger,
.contact-form form #msgSubmit.text-success {
    margin-top: 8px;
    font-size: 23px;
    font-weight: 700;
}

.contact-info {
    text-align: center;
    margin-top: 60px;
}
.contact-info .contact-info-content h3 {
    margin-bottom: 15px;
    font-size: 23px;
    font-weight: 700;
}
.contact-info .contact-info-content h2 {
    margin-bottom: 0;
    font-size: 40px;
    font-weight: 700;
}
.contact-info .contact-info-content h2 .number {
    display: inline-block;
    color: #ff4800;
}
.contact-info .contact-info-content h2 .number:hover {
    color: #4ac728;
}
.contact-info .contact-info-content h2 .email {
    display: inline-block;
    color: #0049a0;
}
.contact-info .contact-info-content h2 .email:hover {
    color: #ff4800;
}
.contact-info .contact-info-content h2 .or {
    display: block;
    color: #57647c;
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
}
.contact-info .contact-info-content .social {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 20px;
}
.contact-info .contact-info-content .social li {
    display: inline-block;
    margin: 0 5px;
}
.contact-info .contact-info-content .social li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #dadada;
    border-radius: 50%;
    color: #aba5a5;
}
.contact-info .contact-info-content .social li a i {
    font-size: 14px;
}
.contact-info .contact-info-content .social li a:hover {
    color: #ffffff;
    border-color: #ff4800;
    background-color: #ff4800;
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    position: relative;
    z-index: 1;
    padding-top: 100px;
    background-color: #15171a;
}

.single-footer-widget {
    margin-bottom: 30px;
}
.single-footer-widget .logo {
    margin-bottom: 20px;
}
.single-footer-widget .logo p {
    color: #ffffff;
    margin-top: 18px;
    margin-bottom: 0;
}
.single-footer-widget p {
    color: #ffffff;
    font-size: 14px;
}
.single-footer-widget .social {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.single-footer-widget .social li {
    display: inline-block;
    transition: 0.5s;
    margin-top: 10px;
    margin-right: 10px;
}
.single-footer-widget .social li:last-child {
    margin-right: 0;
}
.single-footer-widget .social li a {
    width: 35px;
    height: 35px;
    background-color: #212529;
    line-height: 35px;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
}
.single-footer-widget .social li a i::before {
    font-size: 13px;
}
.single-footer-widget .social li a:hover {
    background-color: #ff4800;
    color: #ffffff;
}
.single-footer-widget h3 {
    margin-bottom: 23px;
    color: #ffffff;
    position: relative;
    padding-bottom: 6px;
    font-size: 23px;
    font-weight: 700;
}
.single-footer-widget h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 2px;
    background: #ff4800;
}
.single-footer-widget .footer-services-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.single-footer-widget .footer-services-list li {
    margin-bottom: 12px;
    color: #ffffff;
    font-size: 14px;
}
.single-footer-widget .footer-services-list li a {
    display: inline-block;
    color: #ffffff;
}
.single-footer-widget .footer-services-list li a:hover {
    color: #ff4800;
}
.single-footer-widget .footer-services-list li:last-child {
    margin-bottom: 0;
}
.single-footer-widget .quick-links-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.single-footer-widget .quick-links-list li {
    margin-bottom: 12px;
    color: #ffffff;
    font-size: 14px;
}
.single-footer-widget .quick-links-list li a {
    display: inline-block;
    color: #ffffff;
}
.single-footer-widget .quick-links-list li a:hover {
    color: #ff4800;
}
.single-footer-widget .quick-links-list li:last-child {
    margin-bottom: 0;
}
.single-footer-widget .footer-contact-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.single-footer-widget .footer-contact-list li {
    margin-bottom: 12px;
    color: #ffffff;
    font-size: 14px;
    line-height: 1.7;
}
.single-footer-widget .footer-contact-list li a {
    display: inline-block;
    color: #ffffff;
}
.single-footer-widget .footer-contact-list li a:hover {
    color: #ff4800;
}
.single-footer-widget .footer-contact-list li span {
    font-weight: 700;
    margin-right: 5px;
}
.single-footer-widget .footer-contact-list li:last-child {
    margin-bottom: 0;
}

.copyright-area {
    background-color: #000000;
    margin-top: 70px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.copyright-area p {
    color: #ffffff;
}
.copyright-area p a {
    display: inline-block;
    color: #ffffff;
    font-weight: 600;
}
.copyright-area p a:hover {
    color: #ff4800;
}
.copyright-area ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    text-align: right;
}
.copyright-area ul li {
    display: inline-block;
    margin-left: 25px;
    position: relative;
    color: #ffffff;
    font-size: 14px;
    line-height: initial;
}
.copyright-area ul li a {
    color: #ffffff;
}
.copyright-area ul li a:hover {
    color: #ff4800;
}
.copyright-area ul li::before {
    content: "";
    width: 4px;
    height: 1px;
    background: #ffffff;
    left: -15px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}
.copyright-area ul li:first-child {
    margin-left: 0;
}
.copyright-area ul li:first-child::before {
    display: none;
}

.circle-map {
    position: absolute;
    top: 20%;
    right: 18%;
    z-index: -1;
}

.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;
}
.lines .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}
.lines .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 75%,
        #ffffff 100%
    );
    animation: run 7s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(1) {
    margin-left: -25%;
}
.lines .line:nth-child(1)::after {
    animation-delay: 2s;
}
.lines .line:nth-child(3) {
    margin-left: 25%;
}
.lines .line:nth-child(3)::after {
    animation-delay: 2.5s;
}

@keyframes run {
    0% {
        top: -50%;
    }
    100% {
        top: 110%;
    }
}
/*================================================
Go Top CSS
=================================================*/
.scroll-to-top .top {
    position: fixed;
    cursor: pointer;
    bottom: 15px;
    right: 15px;
    color: #ffffff;
    background: #ff4800;
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 42px;
    line-height: 42px;
    transition: 0.9s;
}
.scroll-to-top .top i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: 0.5s;
}
.scroll-to-top .top i:last-child {
    opacity: 0;
    visibility: hidden;
    top: 60%;
}
.scroll-to-top .top::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #4ac728;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
}
.scroll-to-top .top:hover,
.scroll-to-top .top:focus {
    color: #ffffff;
}
.scroll-to-top .top:hover::before,
.scroll-to-top .top:focus::before {
    opacity: 1;
    visibility: visible;
}
.scroll-to-top .top:hover i:first-child,
.scroll-to-top .top:focus i:first-child {
    opacity: 0;
    top: 0;
    visibility: hidden;
}
.scroll-to-top .top:hover i:last-child,
.scroll-to-top .top:focus i:last-child {
    opacity: 1;
    visibility: visible;
    top: 50%;
}

/* Text container */
.text-container h1,
.text-container h2,
.text-container h3,
.text-container h4,
.text-container h5,
.text-container h6 {
    margin-bottom: 15px;
}
.text-container ul,
.text-container ol {
    padding-left: 17px;
}
.text-container ul li,
.text-container ol li {
    line-height: 1.8;
    margin-bottom: 6px;
}
.text-container ul li:last-child,
.text-container ol li:last-child {
    margin-bottom: 0;
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.why-choose-us-content h2 {
    margin-bottom: 15px;
    font-size: 40px;
    font-weight: 700;
}
.why-choose-us-content .btn {
    margin-top: 25px;
}
.why-choose-us-content .row {
    margin-top: -15px;
}

.single-why-choose-us-box {
    margin-top: 30px;
}
.single-why-choose-us-box h3 {
    margin-bottom: 12px;
    font-size: 21px;
    font-weight: 700;
}

/*================================================
Solutions Area CSS
=================================================*/
.solutions-area {
    padding-bottom: 80px;
    background-color: #ffefe9;
    background-image: url(../../public/images/solutions-bg.webp);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.single-solutions-box {
    margin-bottom: 30px;
    padding: 30px;
    border: 1px solid transparent;
    background-color: transparent;
    transition: 0.5s;
}
.single-solutions-box .icon {
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    width: 75px;
    height: 75px;
    line-height: 75px;
    transition: 0.5s;
    text-align: center;
    color: #ff4800;
}
.single-solutions-box .icon i::before {
    font-size: 35px;
}
.single-solutions-box h3 {
    margin-bottom: 12px;
    font-size: 23px;
    font-weight: 700;
}
.single-solutions-box.active,
.single-solutions-box:hover {
    border-color: #ff4800;
    box-shadow: 5px 5px #ff4800;
    background-color: #ffffff;
}
.single-solutions-box.active .icon,
.single-solutions-box:hover .icon {
    background-color: #ff4800;
    color: #ffffff;
}

/*================================================
Testimonials Area CSS
=================================================*/
.testimonials-section .section-title {
    margin-bottom: 50px;
}
.testimonials-section .section-title .content {
    text-align: right;
    padding-right: 30px;
    position: relative;
}
.testimonials-section .section-title .content::before {
    content: "";
    position: absolute;
    right: -15px;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #ffe3d8;
}
.testimonials-section .section-title .icon {
    text-align: left;
    margin-bottom: -15px;
    padding-left: 30px;
}
.testimonials-section .section-title .icon i {
    color: #ff4800;
    line-height: 1;
}
.testimonials-section .section-title .icon i::before {
    line-height: 1;
    font-size: 90px;
}
.testimonials-section .testimonials-slides {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
}

.single-testimonials-box {
    text-align: center;
    margin-bottom: 30px;
}
.single-testimonials-box p {
    color: #212529;
    margin-bottom: 0;
    font-size: 22px;
}
.single-testimonials-box .client-info {
    margin-top: 25px;
}
.single-testimonials-box .client-info h3 {
    margin-bottom: 9px;
    font-size: 20px;
    font-weight: 700;
}
.single-testimonials-box .client-info span {
    color: #ff4800;
}

.testimonials-area {
    background: linear-gradient(
        to right,
        #dee4f3,
        #e7eaf6,
        #f0f1f9,
        #f8f8fc,
        #ffffff
    );
    padding-bottom: 80px;
    position: relative;
    z-index: 1;
}

.single-testimonials-item {
    box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.04);
    margin-bottom: 45px;
    background-color: #ffffff;
    position: relative;
    padding: 60px 70px 60px 190px;
    border-radius: 5px;
}
.single-testimonials-item .client-info {
    position: absolute;
    left: 70px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}
.single-testimonials-item .client-info img {
    border-radius: 5px;
    border: 2px solid #f4f5fe;
    width: 80px !important;
    height: 80px !important;
    padding: 5px;
    display: inline-block !important;
}
.single-testimonials-item .client-info h3 {
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 19px;
    font-weight: 700;
}
.single-testimonials-item .client-info span {
    display: block;
    color: #ff4800;
    margin-top: 5px;
    font-size: 13px;
}
.single-testimonials-item p {
    font-size: 15px;
    margin-bottom: 0;
    color: #4a6f8a;
}
.single-testimonials-item .rating {
    margin-top: 10px;
}
.single-testimonials-item .rating i {
    color: #ff612f;
    margin-right: 4px;
}
.single-testimonials-item .rating i:last-child {
    margin-right: 0;
}
.single-testimonials-item .rating i::before {
    font-size: 14px;
}
.single-testimonials-item::before {
    content: "\f113";
    color: #f5f5f5;
    position: absolute;
    right: 25px;
    bottom: 0;
    font-style: normal;
    font-size: 80px;
    font-family: Flaticon;
}
.single-testimonials-item::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 5px;
    bottom: -15px;
    background-color: #ffffff;
    box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.04);
    z-index: -1;
    margin-left: 30px;
    margin-right: 30px;
}

.about-img {
    text-align: center;
}

.testimonials-slides {
    max-width: 750px;
    margin: -40px auto 0;
}
.testimonials-slides .swiper-slide {
    padding-left: 50px;
    padding-right: 50px;
}
.testimonials-slides .single-testimonials-item {
    margin-top: 40px;
}
.testimonials-slides .swiper-pagination {
    position: initial;
}
.testimonials-slides .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
}
.testimonials-slides
    .swiper-pagination
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #ff4800;
}

/*================================================
Projects Details Area CSS
=================================================*/
.projects-details {
    padding: 40px;
    box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
    background-color: #ffffff;
}

.projects-details-image {
    box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.3);
    background-color: #ffffff;
}

.projects-details-info {
    width: 100%;
    height: 100%;
    padding: 25px;
    box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
    background-color: #ffffff;
}
.projects-details-info ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
.projects-details-info ul li {
    margin-bottom: 12px;
    color: #57647c;
}
.projects-details-info ul li:last-child {
    margin-bottom: 0;
}
.projects-details-info ul li a {
    display: inline-block;
    color: #57647c;
}
.projects-details-info ul li a:hover {
    color: #ff4800;
}
.projects-details-info ul li span {
    color: #212529;
    font-weight: 600;
    font-size: 16px;
}

.projects-details-desc {
    margin-top: 35px;
}
.projects-details-desc h3 {
    margin-bottom: 12px;
    font-size: 23px;
    font-weight: 700;
}
.projects-details-desc p {
    margin-bottom: 12px;
}
.projects-details-desc blockquote p,
.projects-details-desc .blockquote p {
    font-size: 25px;
}

/*================================================
Product Details Area CSS
=================================================*/

.tab .tabs_item {
    display: none;
}
.tab .tabs_item:first-child {
    display: block;
}

.products-details-tab {
    margin-top: 50px;
}
.products-details-tab .tabs {
    list-style-type: none;
    margin-bottom: -1px;
    padding-left: 0;
}
.products-details-tab .tabs li {
    display: inline-block;
    line-height: initial;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    color: #212529;
    border: 1px dashed #eeeeee;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 48px;
    font-weight: 700;
    font-family: "Dosis", sans-serif;
}
.products-details-tab .tabs li .dot {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
    width: 12px;
    height: 12px;
    border: 1px solid #212529;
    transition: 0.5s;
    border-radius: 50%;
}
.products-details-tab .tabs li .dot::before {
    position: absolute;
    top: 0;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    background: #212529;
    margin: 2px;
    border-radius: 50%;
    transition: 0.5s;
}
.products-details-tab .tabs li:hover,
.products-details-tab .tabs li:focus {
    color: #ffffff;
    background-color: #ff4800;
    border-color: #ff4800;
}
.products-details-tab .tabs li:hover .dot,
.products-details-tab .tabs li:focus .dot {
    border-color: #ffffff;
}
.products-details-tab .tabs li:hover .dot::before,
.products-details-tab .tabs li:focus .dot::before {
    background: #ffffff;
}
.products-details-tab .tabs li.current {
    color: #ffffff;
    background-color: #ff4800;
    border-color: #ff4800;
}
.products-details-tab .tabs li.current .dot {
    border-color: #ffffff;
}
.products-details-tab .tabs li.current .dot::before {
    background: #ffffff;
}
.products-details-tab .tabs li:last-child {
    margin-right: 0;
}
.products-details-tab .tab_content {
    border: 1px dashed #eeeeee;
    padding: 30px;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content p {
    margin-bottom: 20px;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    p:last-child {
    margin-bottom: 0;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .additional-information {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    text-align: left;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .additional-information
    li {
    border: 1px solid #eeeeee;
    border-bottom: none;
    padding: 10px 15px;
    color: #57647c;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .additional-information
    li:last-child {
    border-bottom: 1px solid #eeeeee;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .additional-information
    li
    span {
    display: inline-block;
    width: 30%;
    color: #212529;
    font-family: "Dosis", sans-serif;
    font-size: 16px;
    font-weight: 600;
}
.products-details-tab .tab_content .tabs_item .products-details-tab-content h3 {
    margin-bottom: 15px;
    font-size: 23px;
    font-weight: 700;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-title {
    position: relative;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-title
    .rating {
    display: inline-block;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-title
    .rating
    .fas.fa-star {
    color: #ffba0a;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-title
    .rating
    i {
    color: #ebebeb;
    font-size: 14px;
    margin-right: 2px;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-title
    p {
    margin-bottom: 0;
    display: inline-block;
    padding-left: 5px;
    line-height: initial;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-title
    .btn {
    position: absolute;
    right: 0;
    bottom: 0;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-comments {
    margin-top: 35px;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-comments
    .review-item {
    margin-top: 30px;
    position: relative;
    padding-right: 200px;
    border-top: 1px dashed #eeeeee;
    padding-top: 30px;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-comments
    .review-item
    .rating
    .fas.fa-star {
    color: #ffba0a;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-comments
    .review-item
    .rating
    i {
    font-size: 14px;
    color: #ebebeb;
    margin-right: 2px;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-comments
    .review-item
    h3 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-comments
    .review-item
    span {
    margin-bottom: 10px;
    font-size: 13px;
    display: block;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-comments
    .review-item
    span
    strong {
    font-weight: 600;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-comments
    .review-item
    p {
    margin-bottom: 0;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-comments
    .review-item
    .review-report-link {
    position: absolute;
    right: 0;
    color: #57647c;
    top: 40px;
    text-decoration: underline;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-comments
    .review-item
    .review-report-link:hover {
    color: #ff4800;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-form {
    margin-top: 30px;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-form
    form
    .form-group {
    margin-bottom: 20px;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-form
    form
    .form-group
    label {
    color: #57647c;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Dosis", sans-serif;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-form
    form
    .form-group
    textarea {
    padding-top: 15px;
}
.products-details-tab
    .tab_content
    .tabs_item
    .products-details-tab-content
    .product-review-form
    .review-form
    form
    .btn {
    margin-top: 0;
}

.related-products {
    margin-top: 70px;
}
.related-products .section-title {
    margin-bottom: 10px;
}
.related-products .single-product-box {
    margin-bottom: 0;
    margin-top: 30px;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pb-70 {
    padding-bottom: 70px;
}

.section-title.with-underline-text {
    max-width: 700px;
}
.section-title.with-underline-text h2 b {
    display: inline-block;
    text-decoration-line: underline;
    color: #437fec;
    font-weight: bold;
}
.section-title.with-underline-text p {
    max-width: 100%;
}

/*================================================
Topbar Wrap Area CSS
=================================================*/
.topbar-wrap-area {
    background-color: #ffffff;
    box-shadow: 0px 6px 15px rgba(71, 113, 188, 0.07);
    padding-top: 15px;
    padding-bottom: 15px;
}
.topbar-wrap-area .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
}

.topbar-content span {
    font-size: 14px;
    font-weight: 600;
}
.topbar-content span a {
    color: #ff4800;
    border-bottom: 1px solid #ff4800;
    display: inline-block;
}
.topbar-content span a:hover {
    color: #4ac728;
    border-bottom: 1px solid #4ac728;
}

.topbar-action-list {
    padding: 0;
    margin-bottom: 0;
    text-align: end;
}
.topbar-action-list li {
    font-size: 14px;
    font-weight: 600;
    list-style-type: none;
    display: inline-block;
    margin-right: 30px;
    position: relative;
    padding-left: 20px;
}
.topbar-action-list li::before {
    content: "";
    position: absolute;
    top: 1px;
    right: -15px;
    width: 1px;
    height: 20px;
    border: 2px solid #e4e7fb;
}
.topbar-action-list li:last-child {
    margin-right: 0;
}
.topbar-action-list li:last-child::before {
    display: none;
}
.topbar-action-list li:first-child i {
    left: 2px;
}
.topbar-action-list li i {
    position: absolute;
    left: 0;
    top: 5px;
    color: #ff4800;
    font-size: 12.5px;
}
.topbar-action-list li a:hover {
    color: #4ac728;
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area-with-position-relative {
    position: relative;
    /* background: linear-gradient(180deg, #f5fcfe 3.31%, #f6fcff 100%); */
}
.navbar-area-with-position-relative.with-white-color {
    background: #ffffff;
}
.navbar-area-with-position-relative .main-nav {
    padding-left: 0;
    padding-right: 0;
}
.navbar-area-with-position-relative .main-nav .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
}
.navbar-area-with-position-relative .main-nav .navbar .navbar-nav .nav-item a {
    color: #212529;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    a:hover,
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    a:focus,
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    a.active {
    color: #ff4800;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .navbar-nav
    .nav-item:hover
    a,
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .navbar-nav
    .nav-item:focus
    a,
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .navbar-nav
    .nav-item.active
    a {
    color: #ff4800;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    a {
    color: #212529;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    a:hover,
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    a:focus,
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    li
    a.active {
    color: #ff4800;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .others-options
    .cart-btn {
    color: #212529;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .others-options
    .cart-btn:hover {
    color: #ff4800;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .others-options
    .btn-primary {
    background-color: #0049a0;
    color: #ffffff;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .others-options
    .btn-primary:hover {
    color: #ffffff !important;
    background-color: #ff4800 !important;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .others-options
    .option-item {
    color: #212529;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .others-options
    .option-item
    .search-btn {
    color: #212529;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .others-options
    .option-item
    .search-btn:hover {
    color: #ff4800;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .others-options
    .option-item
    .close-btn {
    color: #212529;
}
.navbar-area-with-position-relative
    .main-nav
    .navbar
    .others-options
    .option-item
    .close-btn:hover {
    color: #ff4800;
}

/*================================================
Top Featured Area CSS
=================================================*/
.top-featured-content {
    margin-bottom: 30px;
}
.top-featured-content h2 {
    font-size: 40px;
    margin-bottom: 15px;
    font-weight: bold;
}
.top-featured-content h2 span {
    text-decoration-line: underline;
    color: #437fec;
}
.top-featured-content p {
    margin-bottom: 0;
}
.top-featured-content .featured-btn {
    margin-top: 20px;
}

.top-featured-card {
    margin-bottom: 30px;
    background: #f8fbff;
    padding: 35px 30px;
    border-radius: 5px;
}
.top-featured-card .image-icon {
    display: inline-block;
    height: 100px;
    width: 100px;
    line-height: 100px;
    background-color: #ffffff;
    box-shadow: -3px 8px 20px rgba(67, 127, 236, 0.05);
    text-align: center;
    border-radius: 50%;
    margin-bottom: 20px;
    transition: 0.5s;
    position: relative;
    z-index: 1;
}
.top-featured-card .image-icon::before {
    position: absolute;
    content: "";
    display: inline-block;
    height: 100px;
    width: 100px;
    line-height: 100px;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(0deg, #ffc2cc 0%, #fff2f4 100%);
    z-index: -1;
    transition: 0.5s;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
}
.top-featured-card h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
}
.top-featured-card .learn-btn {
    background-color: #ffffff;
    box-shadow: -3px 8px 20px rgba(67, 127, 236, 0.05);
    display: inline-block;
    padding: 10px 25px;
    border-radius: 30px;
    color: #57647c;
    font-weight: 600;
    margin-top: 22px;
}
.top-featured-card:hover .image-icon {
    animation: jackInTheBox 1s;
}
.top-featured-card:hover .image-icon::before {
    opacity: 1;
    visibility: visible;
}
.top-featured-card:hover .learn-btn {
    color: #ff4800;
}

.top-featured-btn {
    text-align: center;
    margin-top: 10px;
}

.top-featured-inner-box {
    position: relative;
    z-index: 1;
    margin-top: -100px;
}

/*================================================
Main Services Area CSS
=================================================*/
.main-services-area {
    padding-bottom: 80px;
}

.single-main-services-box {
    margin-bottom: 30px;
    border: 1px solid #ffd1bf;
    padding: 30px;
    /* height: 260px; */
    transition: 0.5s;
    box-shadow: 5px 5px #ffd1bf;
}
.single-main-services-box .icon {
    margin-bottom: 22px;
    line-height: 1;
    color: #ff4800;
}
.single-main-services-box .icon i::before {
    font-size: 50px;
}
.single-main-services-box h3 {
    margin-bottom: 12px;
    font-size: 23px;
    font-weight: 700;
}
.single-main-services-box .link-btn {
    display: inline-block;
    font-weight: 600;
    color: #ff4800;
}
.single-main-services-box:hover {
    border-color: #ff4800;
    box-shadow: 5px 5px #ff4800;
}

.features-component {
    background: linear-gradient(
        to right,
        #dee4f3,
        #e7eaf6,
        #f0f1f9,
        #f8f8fc,
        #ffffff
    );
    padding-bottom: 80px;
    position: relative;
    z-index: 1;
}
